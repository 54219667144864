<template>
  <div class="bmi padding-xl-top" title="Body Mass Index">
    <!--span class="h4">
    Il tuo IMC </span-->
    <span class="float-right h2">
      <span id="bmi-result-number" :style="'color:' + colore_indice"
        ><strong>{{ indice }}</strong></span
      >
    </span>
    <div class="bmi-scale margin-l-bottom margin-l-top clear-fix">
      <div
        class="icon-arrow-down"
        id="bmi-indicator"
        :style="'left:' + (perc_indice - 3) + '%'"
      >
        <b-icon icon="caret-down-fill" variant="warning"></b-icon>
      </div>
      <span class="bmi-step step-1"></span>
      <span class="bmi-step step-2"></span>
      <span class="bmi-step step-3"></span>
      <span class="bmi-step step-4"></span>
      <span class="bmi-step step-5"></span>
      <span class="bmi-step step-6"></span>
      <span class="bmi-step step-7"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    peso: {
      type: Number,
      default: 0,
    },
    altezza: {
      type: Number,
      default: 0,
    },
    eta: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    indice: function () {
      var indice = 0;
      if (this.peso != 0 && this.altezza != 0) {
        indice = Math.round(this.peso / Math.pow(this.altezza / 100, 2));
      }
      return indice;
    },

    colore_indice: function () {
      if (
        this.indice >= this.imc_ideale_min &&
        this.indice <= this.imc_ideale_max
      ) {
        return "#70b62f";
      }
      if (this.indice < this.imc_ideale_min) {
        return "#3faec6";
      }
      if (this.indice > this.imc_ideale_max && this.indice < 30) {
        return "#b87476";
      }
      return "#d73228";
    },

    perc_indice: function () {
      if (
        this.indice >= this.imc_ideale_min &&
        this.indice <= this.imc_ideale_max
      ) {
        if (
          this.indice - this.imc_ideale_min <
          this.imc_ideale_max - this.indice
        ) {
          return 30;
        } else return 43;
      }
      if (this.indice < this.imc_ideale_min) {
        if (this.indice <= 16) {
          return 6;
        } else return 18;
      }
      if (this.indice > this.imc_ideale_max && this.indice < 30) {
        return 55;
      }
      if (this.indice >= 30 && this.indice < 40) {
        return 68;
      }
      if (this.indice >= 40) {
        return 81;
      }
      return 0;
    },

    imc_ideale_min: function () {
      if (this.eta <= 16) {
        return 19;
      } else if (this.eta == 17) {
        return 20;
      } else if (this.eta == 18) {
        return 20;
      } else if (this.eta >= 19 && this.eta <= 24) {
        return 21;
      } else if (this.eta >= 25 && this.eta <= 34) {
        return 22;
      } else if (this.eta >= 35 && this.eta <= 54) {
        return 23;
      } else if (this.eta >= 55 && this.eta <= 64) {
        return 24;
      } else if (this.eta >= 65 && this.eta <= 90) {
        return 25;
      }
      return 0;
    },

    imc_ideale_max: function () {
      if (this.eta <= 16) {
        return 24;
      } else if (this.eta == 17) {
        return 25;
      } else if (this.eta == 18) {
        return 25;
      } else if (this.eta >= 19 && this.eta <= 24) {
        return 26;
      } else if (this.eta >= 25 && this.eta <= 34) {
        return 27;
      } else if (this.eta >= 35 && this.eta <= 54) {
        return 28;
      } else if (this.eta >= 55 && this.eta <= 64) {
        return 29;
      } else if (this.eta >= 65 && this.eta <= 90) {
        return 30;
      }
      return 0;
    },
  },
};
</script>
<style>
.bmi-scale {
  width: 100%;
  /* background-color: #ededed;*/
  background-color: #333333;
  height: 20px;
  padding: 0 4px;
}

.bmi-scale,
.bmi-step {
  border-radius: 4px;
  position: relative;
}

.bmi-step {
  background-color: #3faec6;
  height: 4px;
  width: 12%;
  margin-left: 1%;
  display: inline-block;
  top: -4px;
}

.step-3,
.step-4 {
  background-color: #70b62f;
}

.step-5 {
  background-color: #b87476;
}

.step-6,
.step-7 {
  background-color: #d73228;
}

.bmi-scale .icon-arrow-down {
  position: absolute;
  z-index: 1;
  top: -25px;
  font-size: 30px;
  left: 0;
  -webkit-transition: left 2s;
  transition: left 2s;
}

.bmi .h2 {
  margin-top: -30px;
}

.bmi {
  /*  background: #999;*/
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.padding-xl-top {
  padding-top: 20px;
}

.number-3,
.number-4 {
  color: #70b62f;
}

.float-right {
  float: right;
}

.margin-l-top {
  margin-top: 15px;
}
.margin-l-bottom {
  margin-bottom: 15px;
}
</style>

<template id="similar_players">
  <b-table-simple small responsive>
    <b-thead>
      <b-tr>
        <b-th colspan="3">Principles</b-th>
        <b-th
          class="text-center border-left border-right"
          :style="'cursor:pointer;color:#000000;background:' + bg_opta[index]"
          :colspan="th_colspan[index]"
          v-for="(item, index) in th_labels"
          :key="'princ_' + index"
          >{{ item }}</b-th
        >
        <b-th
          v-if="th_others > 0"
          class="text-center border-left border-right"
          :colspan="th_others"
          style="cursor: pointer; color: #000000; background: #ffffff"
          >OTHERS</b-th
        >
      </b-tr>
      <b-tr>
        <b-th></b-th>
        <b-th>Players</b-th>
        <b-th>Age</b-th>
        <b-th class="text-center">Distance</b-th>
        <b-th
          class="text-center"
          v-for="skill_id in skill_ids"
          :key="skill_id"
          >{{ getSkillById(skill_id).label }}</b-th
        >
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr v-for="(player, index) in players" :key="player.player_id">
        <b-td style="vertical-align: middle"
          ><b-img :src="player.avatar" height="30"></b-img
        ></b-td>
        <b-td style="vertical-align: middle"
          ><b-link
            v-if="index > 0"
            :href="'/scouting/view/player/' + player.player_id"
            target="_blank"
            >{{ player.name }}</b-link
          >
          <div v-else style="color: #ff0000">{{ player.name }}</div>
        </b-td>
        <b-td style="vertical-align: middle" class="text-center">{{
          player.age ? player.age : "N/D"
        }}</b-td>
        <b-td style="vertical-align: middle" class="text-center">{{
          player.distance
        }}</b-td>
        <b-td
          class="text-center"
          v-for="skill_id in skill_ids"
          :key="skill_id"
          style="vertical-align: middle"
        >
          <div
            v-if="player.skills[skill_id]"
            :style="'background:' + player.skills[skill_id].color"
            class="box-small"
          >
            {{ player.skills[skill_id].value }}
          </div>
          <div v-else class="text-center">N/D</div>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
export default {
  props: {
    skills: {
      type: Array,
      required: true,
    },
    skill_ids: {
      type: Array,
      required: true,
    },
    players: {
      type: Array,
      required: true,
    },
    principles_skills: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
        "#FF9999",
        "#CCCCCC",
        "#6A5ACD",
      ],

      principles_title: [
        "PRESSING",
        "RECOVERY",
        "MARKING",
        "BUILD UP",
        "POSSESSION",
        "ENGAGEMENT",
        "UNMARKING",
        "DRIBBLING",
        "WIDE PLAY",
        "FINISHING",
        "CREATIVITY",
        "GOALKEEPER",
        "SET PLAY",
      ],
    };
  },

  methods: {
    getSkillById(id) {
      return this.skills.find((skill) => skill.id == id);
    },
  },
  computed: {
    th_labels: function () {
      var th_labels = [];
      for (var i in this.principles_skills) {
        for (var j in this.principles_skills[i]) {
          var skill_id = this.principles_skills[i][j];
          if (this.skill_ids.includes(skill_id)) {
            th_labels.push(this.principles_title[i]);
            break;
          }
        }
      }
      return th_labels;
    },

    th_colspan: function () {
      var th_colspan = [];
      for (var i in this.principles_skills) {
        var colspan = 0;
        for (var j in this.principles_skills[i]) {
          var skill_id = this.principles_skills[i][j];
          if (this.skill_ids.includes(skill_id)) {
            colspan++;
          }
        }
        if (colspan > 0) {
          th_colspan.push(colspan);
        }
      }
      return th_colspan;
    },

    th_others: function () {
      var count_others = 0;
      for (var s in this.skill_ids) {
        var skill_id = this.skill_ids[s];
        for (var i in this.principles_skills) {
          if (this.principles_skills[i].includes(skill_id)) {
            count_others++;
            break;
          }
        }
      }
      return count_others;
    },
  },
};
</script>

<template id="playercard">
  <b-container fluid v-if="player">
    <b-navbar type="dark" class="px-0">
      <b-navbar-nav :class="!auth.isAdmin() ? 'no-admin' : ''">
        <b-nav-item
          v-if="player.giocatore && auth.isUserEnable('player_edit')"
          :to="'/scouting/edit/player/' + player.giocatore.id"
        >
          Data edit
        </b-nav-item>
        <b-nav-item
          v-if="!player.giocatore && auth.isUserEnable('player_add')"
          :to="
            '/scouting/new/player/' + player.lastName + '/' + player.firstName
          "
        >
          Data edit
        </b-nav-item>
        <b-nav-item
          v-if="player.giocatore && auth.isUserEnable('player_eval')"
          :to="'/scouting/eval/player/' + player.giocatore.id"
        >
          Evaluation
        </b-nav-item>
        <b-nav-item
          v-if="
            player.giocatore &&
            player.giocatore.wyscout_id &&
            auth.isUserEnable('player_videotag')
          "
          :to="'/scouting/videotag/player/' + player.giocatore.id"
        >
          Videotag
        </b-nav-item>
        <b-nav-item
          v-if="auth.isUserEnable('opta')"
          :to="'/scouting/player/index/games/' + player.id"
        >
          Matches
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-button
          class="mr-3"
          size="sm"
          variant="info"
          @click="fullscreen = !fullscreen"
          >{{
            fullscreen ? "Exit fullscreen video" : "Enter fullscreen video"
          }}
          <b-icon
            :icon="fullscreen ? 'fullscreen-exit' : 'fullscreen'"
            class="ml-2"
          ></b-icon
        ></b-button>
        <b-button size="sm" variant="primary" v-b-toggle.sidebar-right
          >Filter stats <b-icon icon="search" class="ml-2"></b-icon
        ></b-button>
      </b-navbar-nav>
    </b-navbar>
    <b-sidebar
      id="sidebar-right"
      title="Filter stats"
      bg-variant="dark-transparent"
      text-variant="light"
      right
      shadow
    >
      <b-row class="px-2 pt-0 pb-2">
        <b-col>
          <b-form-group label="From" class="mb-0">
            <b-form-datepicker
              v-model="filters.from"
              :locale="ita ? 'it' : 'en-US'"
              size="sm"
              :dark="true"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="px-2 pt-0 pb-2">
        <b-col>
          <b-form-group label="To" class="mb-0">
            <b-form-datepicker
              v-model="filters.to"
              :locale="ita ? 'it' : 'en-US'"
              size="sm"
              :dark="true"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="px-2 pt-0 pb-2">
        <b-col>
          <b-form-group label="Minimun minutes played" class="mb-0">
            <b-form-input
              type="number"
              v-model="filters.minutes"
              :disabled="reload_filters"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="px-2 pt-0 pb-2">
        <b-col>
          <b-form-group label="Age from" class="mb-0">
            <b-form-input
              type="number"
              v-model="filters.age_from"
              :disabled="reload_filters"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Age to" class="mb-0">
            <b-form-input
              type="number"
              v-model="filters.age_to"
              :disabled="reload_filters"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col>
          <multiselect
            v-model="filters.clusters"
            :multiple="true"
            :options="filters_options.clusters"
            group-values="clusters"
            group-label="all"
            :group-select="true"
            placeholder="Opta clusters"
            label="name"
            :custom-label="labelCluster"
            track-by="id"
            :hideSelected="true"
            selectLabel=""
            deselectLabel=""
            :disabled="reload_filters"
          />
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col>
          <multiselect
            v-model="filters.leagues"
            :multiple="true"
            :options="filters_options.leagues"
            placeholder="Opta player leagues"
            label="name"
            track-by="id"
            :hideSelected="true"
            selectLabel=""
            deselectLabel=""
            :disabled="reload_filters"
          />
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col>
          <multiselect
            v-model="filters.player_teams"
            :multiple="true"
            :options="filters_options.player_teams"
            placeholder="Opta player teams"
            label="name"
            track-by="id"
            :hideSelected="true"
            selectLabel=""
            deselectLabel=""
            :disabled="reload_filters"
          />
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col>
          <multiselect
            v-model="filters.teams"
            :multiple="true"
            :options="filters_options.teams"
            placeholder="Opta teams"
            label="name"
            track-by="id"
            :hideSelected="true"
            selectLabel=""
            deselectLabel=""
            :disabled="reload_filters"
          />
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col>
          <multiselect
            v-model="filters.roles"
            :multiple="true"
            :options="filters_options.roles"
            group-values="items"
            group-label="macro"
            :group-select="true"
            placeholder="Opta roles"
            label="ruolo"
            track-by="id"
            :hideSelected="true"
            selectLabel=""
            deselectLabel=""
          />
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col>
          <multiselect
            v-model="filters.games"
            :multiple="true"
            :options="filters_options.games"
            placeholder="Opta games"
            label="label"
            track-by="id"
            :custom-label="labelGame"
            :hideSelected="true"
            selectLabel=""
            deselectLabel=""
            :disabled="reload_filters"
          />
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col>
          <b-button
            :variant="waiting ? 'warning' : 'primary'"
            @click="reload_filters ? getFilters() : getStats()"
            :disabled="waiting"
            >Update
            <b-icon
              icon="arrow-repeat"
              :animation="waiting ? 'spin' : ''"
            ></b-icon
          ></b-button>
        </b-col>
      </b-row>
    </b-sidebar>
    <b-img
      v-if="player.giocatore && player.giocatore.sfondo"
      class="bg-coach"
      :src="player.giocatore.sfondo_url"
    />
    <div id="tooltipdotplot" class="tooltipdotplot">
      <b-row align-v="center">
        <b-col cols="3">
          <img id="imgdotplot" width="35" src="" />
        </b-col>
        <b-col>
          <span id="labeldotplot"></span>
        </b-col>
      </b-row>
    </div>

    <div
      id="tooltipline"
      class="tooltip"
      style="width: 250px; text-align: center"
    >
      <div id="leagueline"></div>
      <img id="imghomeline" height="25" />
      <span id="gameline"></span>
      <img id="imgawayline" height="25" />
      <div id="dateline"></div>
      <div id="scoreline"></div>
      <div id="valueline" style="font-weight: bold; font-size: 1rem"></div>
    </div>

    <b-row align-h="center">
      <player-header
        v-if="!waiting && stats && !fullscreen"
        :player="player"
        :team="last_team"
        :stats="stats"
        :skills="skills"
        :range="!Array.isArray(stats_range) ? stats_range : {}"
      />
      <b-col class="m-0 p-0 pb-2" :cols="fullscreen ? 10 : 6" v-show="!waiting">
        <b-card
          no-body
          v-if="player"
          :class="
            'm-1 h-100 p-1 ' +
            (player.giocatore && player.giocatore.sfondo
              ? 'card-bg-opacity'
              : '')
          "
        >
          <div v-show="playlist.length > 0">
            <b-img
              v-if="showImageEditor"
              style="position: absolute; z-index: 2"
              :src="imageEditor"
              :width="widthEditor"
              :height="heightEditor"
            />
            <video
              id="video-wrapper"
              class="video-js vjs-default-skin vjs-16-9"
              controls
              data-setup="{}"
            ></video>
            <b-pagination
              v-model="page"
              class="mt-2 mb-0"
              :total-rows="playlist.length"
              :per-page="1"
              @change="selectClip"
              size="sm"
              align="center"
              :limit="20"
            ></b-pagination>
          </div>
          <div
            v-show="playlist.length == 0"
            style="text-align: center; margin-top: 100px"
          >
            <h2>No video available</h2>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <div v-if="!waiting && stats.length > 0">
      <b-row>
        <b-col class="p-0">
          <b-card
            no-body
            :class="
              'm-1 ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <div style="width: 100%; display: flex">
              <div
                :id="'meter' + item.id"
                v-bind:key="item.id"
                v-for="item in player_meter"
                :style="
                  'cursor:pointer; flex-grow: 1; background:' + item.color
                "
                class="box-meter"
              >
                {{ item.rank }}
              </div>
            </div>
            <b-tooltip
              :target="'meter' + item.id"
              v-bind:key="item.id"
              v-for="item in player_meter"
              triggers="hover"
            >
              <p>{{ item.name }}: {{ item.value }}</p>
            </b-tooltip>
          </b-card>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col class="p-0">
          <b-card
            :class="
              'm-1 ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <b-row align-v="center">
              <b-col cols="1">
                <b-icon
                  icon="arrow-up-circle-fill"
                  style="
                    position: absolute;
                    top: -35;
                    left: -2;
                    color: #00b386;
                    width: 60px;
                    height: 60px;
                  "
                ></b-icon>
              </b-col>
              <b-col style="margin-left: -10px">
                <h4>
                  {{ ita ? "PUNTI DI FORZA" : "STRENGHTS" }}
                  <span style="cursor: pointer" @click="ita = !ita">
                    <flag
                      v-if="!ita"
                      country="IT"
                      :size="16"
                      class="ml-1"
                      title="IT"
                      style="cursor: pointer"
                    ></flag>
                    <flag
                      v-if="ita"
                      country="GB"
                      :size="16"
                      class="ml-1"
                      title="EN"
                    ></flag>
                  </span>
                </h4>
              </b-col>
              <b-col class="text-center">
                <b-form-radio-group v-model="swot">
                  <b-form-radio value="macro">Macro</b-form-radio>
                  <b-form-radio value="micro">Micro</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row
              align-v="center"
              v-for="item in dot_plot_skills_data_sorted_asc.slice(0, 6)"
              :key="'for' + item.id"
            >
              <b-col cols="1"> </b-col>
              <b-col style="font-size: 0.85rem; margin-left: -10px">{{
                item.label
              }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item.id, getValueSkillById(item.id))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item.id) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: green; font-size: 1.1rem"
                ><strong>{{ item.rank }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
        <b-col class="p-0">
          <b-card
            :class="
              'm-1 ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <b-row align-v="center">
              <b-col cols="1">
                <b-icon
                  icon="arrow-down-circle-fill"
                  style="
                    position: absolute;
                    top: -35;
                    left: -2;
                    color: #ff0059;
                    width: 60px;
                    height: 60px;
                  "
                ></b-icon>
              </b-col>
              <b-col style="margin-left: -10px">
                <h4>
                  {{ ita ? "PUNTI DI DEBOLEZZA" : "WEAKNESSES" }}
                  <span style="cursor: pointer" @click="ita = !ita">
                    <flag
                      v-if="!ita"
                      country="IT"
                      :size="16"
                      class="ml-1"
                      title="IT"
                      style="cursor: pointer"
                    ></flag>
                    <flag
                      v-if="ita"
                      country="GB"
                      :size="16"
                      class="ml-1"
                      title="EN"
                    ></flag>
                  </span>
                </h4>
              </b-col>
              <b-col class="text-center">
                <b-form-radio-group v-model="swot">
                  <b-form-radio value="macro">Macro</b-form-radio>
                  <b-form-radio value="micro">Micro</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row
              align-v="center"
              v-for="item in dot_plot_skills_data_sorted_desc.slice(0, 6)"
              :key="'for' + item.id"
            >
              <b-col cols="1"> </b-col>
              <b-col style="font-size: 0.85rem; margin-left: -10px">{{
                item.label
              }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item.id, getValueSkillById(item.id))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item.id) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: red; font-size: 1.1rem"
                ><strong>{{ item.rank }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col class="p-0">
          <b-card
            :class="
              'm-1 ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <b-row align-v="center">
              <b-col cols="1">
                <b-icon
                  icon="arrow-up-right-circle-fill"
                  style="
                    position: absolute;
                    top: -35;
                    left: -2;
                    color: #b8f100;
                    width: 60px;
                    height: 60px;
                  "
                ></b-icon>
              </b-col>
              <b-col style="margin-left: -10px">
                <h4>
                  {{ ita ? "OPPORTUNITA'" : "OPPORTUNITIES" }}
                  <span style="cursor: pointer" @click="ita = !ita">
                    <flag
                      v-if="!ita"
                      country="IT"
                      :size="16"
                      class="ml-1"
                      title="IT"
                      style="cursor: pointer"
                    ></flag>
                    <flag
                      v-if="ita"
                      country="GB"
                      :size="16"
                      class="ml-1"
                      title="EN"
                    ></flag>
                  </span>
                </h4>
              </b-col>
              <b-col class="text-center">
                <b-form-radio-group v-model="swot">
                  <b-form-radio value="macro">Macro</b-form-radio>
                  <b-form-radio value="micro">Micro</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row
              align-v="center"
              v-for="item in skills_rate_sorted_desc.slice(0, 6)"
              :key="'opp' + item[0]"
            >
              <b-col cols="1"> </b-col>
              <b-col style="font-size: 0.85rem; margin-left: -10px">{{
                getSkillById(item[0]).label
              }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item[0], getValueSkillById(item[0]))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item[0]) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: green; font-size: 1.1rem"
                ><strong>{{ Math.round(item[1] * 100) / 100 }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
        <b-col class="p-0">
          <b-card
            :class="
              'm-1 ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <b-row align-v="center">
              <b-col cols="1">
                <b-icon
                  icon="arrow-down-left-circle-fill"
                  style="
                    position: absolute;
                    top: -35;
                    left: -2;
                    color: #ffb800;
                    width: 60px;
                    height: 60px;
                  "
                ></b-icon>
              </b-col>
              <b-col style="margin-left: -10px">
                <h4>
                  {{ ita ? "MINACCIA" : "THREATS" }}
                  <span style="cursor: pointer" @click="ita = !ita">
                    <flag
                      v-if="!ita"
                      country="IT"
                      :size="16"
                      class="ml-1"
                      title="IT"
                      style="cursor: pointer"
                    ></flag>
                    <flag
                      v-if="ita"
                      country="GB"
                      :size="16"
                      class="ml-1"
                      title="EN"
                    ></flag>
                  </span>
                </h4>
              </b-col>
              <b-col class="text-center">
                <b-form-radio-group v-model="swot">
                  <b-form-radio value="macro">Macro</b-form-radio>
                  <b-form-radio value="micro">Micro</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row
              align-v="center"
              v-for="item in skills_rate_sorted_asc.slice(0, 6)"
              :key="'min' + item[0]"
            >
              <b-col cols="1"> </b-col>
              <b-col style="font-size: 0.85rem; margin-left: -10px">{{
                getSkillById(item[0]).label
              }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item[0], getValueSkillById(item[0]))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item[0]) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: red; font-size: 1.1rem"
                ><strong>{{ Math.round(item[1] * 100) / 100 }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
      </b-row>

      <!-- player-index -->
      <b-row>
        <b-col class="m-0 p-0 mb-3">
          <b-card
            :class="
              'm-1 p-1 h-100 ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <b-row class="mb-5" align-v="center">
              <b-col class="text-center" cols="2">
                <h3>Volatility</h3>
                <h3>
                  <strong>{{ getMediaVolatility([]) }} %</strong>
                </h3>
                <br />
                <h3>Uniqueness</h3>
                <h3>
                  <strong>{{ distance_avg_norm[$route.params.id] }} %</strong>
                </h3>
                <br />
                <h3>Availability</h3>
                <h3>
                  <strong>{{
                    availability != "N/D" ? availability + "%" : "N/D"
                  }}</strong>
                </h3>
              </b-col>
              <b-col cols="2">
                <b-row>
                  <b-col
                    class="text-center"
                    style="cursor: pointer"
                    @click="getRankIndexMicro"
                  >
                    <Progress
                      class="micro"
                      :strokeColor="micro_index ? micro_index.color : '#FFFFFF'"
                      :value="micro_index ? micro_index.value : 0"
                      :radius="70"
                      :strokeWidth="10"
                      :transitionDuration="0"
                    >
                      <template v-slot:footer>
                        <div>micro</div>
                      </template>
                    </Progress>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    class="text-right"
                    style="cursor: pointer"
                    @click="getRankIndexMacro"
                  >
                    <Progress
                      class="total"
                      :strokeColor="total_index ? total_index.color : '#FFFFFF'"
                      :value="total_index ? total_index.value : 0"
                      :radius="40"
                      :strokeWidth="6"
                      :transitionDuration="0"
                    >
                      <template v-slot:footer>
                        <div class="text-center">macro</div>
                      </template>
                    </Progress>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="text-center">
                <radar-index
                  id="principles"
                  :values="principles_index_by_role"
                  :media="principles_index_media_by_role"
                ></radar-index>
              </b-col>
              <b-col class="mx-1" cols="2" align-self="start">
                <b-row v-for="index in [0, 1, 2, 3, 4, 5]" :key="index">
                  <b-col class="border-bottom">
                    <b-row align-v="center">
                      <b-col>
                        {{ principles_title[index] }}
                      </b-col>
                      <b-col
                        class="text-right"
                        style="cursor: pointer"
                        @click="getRankIndexPrinciples(index)"
                      >
                        <Progress
                          class="principle"
                          :strokeColor="principles_index[index].color"
                          :value="principles_index[index].value"
                          :radius="30"
                          :strokeWidth="5"
                          :transitionDuration="0"
                        ></Progress>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="mx-1" cols="2" align-self="start">
                <b-row v-for="index in [6, 7, 8, 9, 10, 11, 12]" :key="index">
                  <b-col
                    class="border-bottom"
                    v-if="
                      (index != 12 && player.id_ruolo == 6) ||
                      (index != 11 && player.id_ruolo != 6)
                    "
                  >
                    <b-row align-v="center">
                      <b-col>
                        {{ principles_title[index] }}
                      </b-col>
                      <b-col
                        class="text-right"
                        style="cursor: pointer"
                        @click="getRankIndexPrinciples(index)"
                      >
                        <Progress
                          class="principle"
                          :strokeColor="principles_index[index].color"
                          :value="principles_index[index].value"
                          :radius="30"
                          :strokeWidth="5"
                          :transitionDuration="0"
                        ></Progress>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <b-col class="p-0 mb-2">
                <b-card
                  v-b-toggle.collapse-skills
                  no-body
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <h2 class="mb-0 mt-1">SKILLS</h2>
                </b-card>
              </b-col>
            </b-row>
            <b-collapse id="collapse-skills">
              <b-row>
                <b-col
                  cols="2"
                  v-for="(item, index) in principles_title_index"
                  :key="index"
                  class="text-center"
                  :style="
                    index != 5 && index != 11
                      ? 'border-right: 1px solid #6C757D'
                      : ''
                  "
                >
                  <div
                    style="border-bottom: 1px solid #ffffff; margin-bottom: 5px"
                  >
                    {{ principles_title[item] }}
                  </div>
                  <b-row>
                    <b-col
                      cols="6"
                      v-for="skill_id in principles_skills[item]"
                      :key="skill_id"
                      class="text-center pl-0 pr-3"
                    >
                      <b-row>
                        <b-col cols="4" class="p-0 text-right">
                          <div
                            style="
                              font-size: 15px;
                              margin-top: 20px;
                              cursor: pointer;
                            "
                            :title="stats_rank[skill_id]"
                          >
                            {{ stats_rank_value[skill_id] }}
                          </div>
                        </b-col>
                        <b-col
                          class="p-0 text-center"
                          style="cursor: pointer"
                          @click="getRankIndexSkill(skill_id)"
                        >
                          <Progress
                            :strokeColor="getIndexSkill(skill_id).color"
                            :value="getIndexSkill(skill_id).value"
                            :radius="28"
                            :strokeWidth="4"
                            :transitionDuration="0"
                          >
                            <template v-slot:footer>
                              <div
                                style="
                                  font-size: 10px;
                                  line-height: 1.5em;
                                  height: 3em;
                                  overflow: hidden;
                                "
                                :title="getSkillById(skill_id).label"
                              >
                                {{ getSkillById(skill_id).label }}
                              </div>
                            </template>
                          </Progress>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col class="m-0 p-0 mb-3">
        <div v-if="waiting_index" class="text-center">
          <img :src="loadingImg" style="width: 150px" />
          <h4>Loading...</h4>
        </div>
        <b-card
          v-if="!waiting_index && !waiting && player_index.length > 0"
          no-body
          :class="
            'm-1 p-1 h-100 ' +
            (player.giocatore && player.giocatore.sfondo
              ? 'card-bg-opacity'
              : '')
          "
        >
          <b-tabs card>
            <b-tab title="MICRO" active>
              <b-card-text
                ><line-chart-index
                  id="micro"
                  :data="player_index_micro"
                  :data_predict="player_index_micro_predict"
              /></b-card-text>
            </b-tab>
            <b-tab title="MACRO">
              <b-card-text
                ><line-chart-index
                  id="macro"
                  :data="player_index_macro"
                  :data_predict="player_index_macro_predict"
              /></b-card-text>
            </b-tab>
            <b-tab
              :title="principles_title[item].substr(0, 3)"
              v-for="item in principles_title_index"
              :key="'lineindex_' + item"
            >
              <b-card-text
                ><line-chart-index
                  :id="'principle_' + item"
                  :data="player_index_principle(item)"
                  :data_predict="player_index_principle_predict(item)"
              /></b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>

    <player-games
      v-if="!waiting && stats"
      :player="player"
      :moduli_home="moduli_home"
      :moduli_away="moduli_away"
      :from="filters.game_from"
      :to="filters.game_to"
      :league="filters.league ? filters.league.name : ''"
    />

    <div v-if="!waiting && stats.length > 0">
      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            :class="
              'm-1 h-100 text-center ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro>
              TOTAL SIMILAR PLAYERS
            </h2>
            <b-collapse id="collapse-micro">
              <total-similar-players
                :total="true"
                :skills="skills"
                :skill_ids="swot == 'micro' ? swot_micro : tsp_skill_ids"
                :players="
                  getGiocatoriSimili(
                    swot == 'micro' ? swot_micro : tsp_skill_ids
                  )
                "
                :principles_skills="principles_skills"
              >
              </total-similar-players>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            v-b-toggle.collapse-1
            no-body
            :class="
              'm-1 h-100 text-center ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">PRESSING</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-1">
        <b-row align-v="center">
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "A quale altezza recupera il pallone?"
                    : "At what Height does the ball recover?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{
                  ita
                    ? "Altezza Recupero del Pallone (mt)"
                    : "Ball ricovery Height (mt)"
                }}
              </h6>
            </div>
          </b-col>
          <b-col cols="2" class="text-right">
            <h6>
              Volatility:
              {{
                getMediaVolatility([
                  56,
                  57,
                  52,
                  51,
                  48,
                  49,
                  50,
                  68,
                  69,
                  70,
                  267,
                  268,
                  269,
                  263,
                  264,
                  262,
                  261,
                  265,
                  266,
                  270,
                ])
              }}%
            </h6>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col
            class="text-center p-0 mb-2"
            v-for="s_id in [56, 57, 52, 262]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </p>

              <half-donut-skill
                :id="'player_' + s_id"
                :data="getDataNormForHalfDonut(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="4">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(263).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(263, getMediaSkillById(263))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(263) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(263, getValueSkillById(263))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(263) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(263) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(263).length > 0"
                :id="'rank' + 263"
                :data="ranking_data(263)"
              />
            </b-card>
          </b-col>

          <b-col cols="2" class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(51).label }}
              </div>
              <b-row class="h-100">
                <b-col>
                  <vertical-bar-skill
                    :id="'rec_' + 51"
                    :data="getDataSkillById(51)"
                    :max="getMaxValueSkillById(51)"
                    :min="getMinValueSkillById(51)"
                    :media="getMediaSkillById(51)"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="4">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(264).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(264, getMediaSkillById(264))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(264) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(264, getValueSkillById(264))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(264) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(264) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(264).length > 0"
                :id="'rank' + 264"
                :data="ranking_data(264)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [48, 49, 50]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="4">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' +
                          getBgStat(s_id, getMediaSkillById(s_id))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' +
                          getBgStat(s_id, getValueSkillById(s_id))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(s_id) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(s_id).length > 0"
                :id="'rank' + s_id"
                :data="ranking_data(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [68, 69, 70]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <horizontal-bar-skill
                :id="'player' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [267, 268, 269]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <horizontal-bar-skill
                :id="'player' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col
            class="text-center p-0 mb-2"
            v-for="s_id in [261, 265, 266, 270]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </p>

              <half-donut-skill
                :id="'player_' + s_id"
                :data="getDataNormForHalfDonut(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="pressing"
                :width="300"
                :height="150"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      56,
                      57,
                      52,
                      51,
                      48,
                      49,
                      50,
                      68,
                      69,
                      70,
                      267,
                      268,
                      269,
                      263,
                      264,
                      262,
                      261,
                      265,
                      266,
                      270,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-1>
                SIMILAR PLAYERS
              </h2>
              <b-collapse id="collapse-micro-1">
                <similar-players
                  :skills="skills"
                  :skill_ids="[
                    56,
                    57,
                    52,
                    51,
                    48,
                    49,
                    50,
                    68,
                    69,
                    70,
                    267,
                    268,
                    269,
                    263,
                    264,
                    262,
                    261,
                    265,
                    266,
                    270,
                  ]"
                  :players="
                    getGiocatoriSimili([
                      56,
                      57,
                      52,
                      51,
                      48,
                      49,
                      50,
                      68,
                      69,
                      70,
                      267,
                      268,
                      269,
                      263,
                      264,
                      262,
                      261,
                      265,
                      266,
                      270,
                    ])
                  "
                >
                </similar-players>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-2
            :class="
              'm-1 h-100 text-center ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">RECOVERY</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-2">
        <b-row align-v="center">
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita ? "Come recupera il pallone?" : "How do you get the ball?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{
                  ita
                    ? "Dinamica per recuperare il pallone"
                    : "Ball ricovery action type"
                }}
              </h6>
            </div>
          </b-col>
          <b-col cols="2" class="text-right">
            <h6>
              Volatility:
              {{
                getMediaVolatility([
                  99,
                  100,
                  96,
                  59,
                  61,
                  60,
                  97,
                  101,
                  77,
                  98,
                  271,
                  272,
                  273,
                  274,
                  275,
                  276,
                  277,
                  278,
                  279,
                ])
              }}%
            </h6>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col
            class="text-center p-0 mb-2"
            v-for="s_id in [99, 100, 96, 97]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </p>

              <half-donut-skill
                :id="'player_' + s_id"
                :data="getDataNormForHalfDonut(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col
            class="text-center p-0 mb-2"
            v-for="s_id in [59, 61, 60, 101]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </p>

              <half-donut-skill
                :id="'player_' + s_id"
                :data="getDataNormForHalfDonut(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2" v-for="s_id in [77, 98]" :key="'col-' + s_id">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="4">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' +
                          getBgStat(s_id, getMediaSkillById(s_id))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' +
                          getBgStat(s_id, getValueSkillById(s_id))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(s_id) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(s_id).length > 0"
                :id="'rank' + s_id"
                :data="ranking_data(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [271, 272, 273, 274, 275, 276]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <vertical-bar-skill
                :id="'build_' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [277, 278, 279]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <horizontal-bar-skill
                :id="'player' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="recovery"
                :width="300"
                :height="150"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      99,
                      100,
                      96,
                      59,
                      61,
                      60,
                      97,
                      101,
                      77,
                      98,
                      271,
                      272,
                      273,
                      274,
                      275,
                      276,
                      277,
                      278,
                      279,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-2>
                SIMILAR PLAYERS
              </h2>
              <b-collapse id="collapse-micro-2">
                <similar-players
                  :skills="skills"
                  :skill_ids="[
                    99,
                    100,
                    96,
                    59,
                    61,
                    60,
                    97,
                    101,
                    77,
                    98,
                    271,
                    272,
                    273,
                    274,
                    275,
                    276,
                    277,
                    278,
                    279,
                  ]"
                  :players="
                    getGiocatoriSimili([
                      99,
                      100,
                      96,
                      59,
                      61,
                      60,
                      97,
                      101,
                      77,
                      98,
                      271,
                      272,
                      273,
                      274,
                      275,
                      276,
                      277,
                      278,
                      279,
                    ])
                  "
                >
                </similar-players>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-3
            :class="
              'm-1 h-100 text-center ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">MARKING</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-3">
        <b-row align-v="center">
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{ ita ? "Abilità difensive" : "Defensive skills" }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
            </div>
          </b-col>
          <b-col cols="2" class="text-right">
            <h6>
              Volatility:
              {{
                getMediaVolatility([
                  71,
                  72,
                  73,
                  78,
                  74,
                  76,
                  53,
                  75,
                  225,
                  226,
                  230,
                  58,
                  55,
                  231,
                  233,
                  227,
                  228,
                  229,
                  232,
                  285,
                  286,
                ])
              }}%
            </h6>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [71, 72, 73, 78, 285]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <horizontal-bar-skill
                :id="'player' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [74, 76, 53, 75, 286]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <horizontal-bar-skill
                :id="'player' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [225, 226, 230]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="4">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' +
                          getBgStat(s_id, getMediaSkillById(s_id))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' +
                          getBgStat(s_id, getValueSkillById(s_id))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(s_id) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(s_id).length > 0"
                :id="'rank' + s_id"
                :data="ranking_data(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col
            class="text-center p-0 mb-2"
            v-for="s_id in [58, 55, 231, 233]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </p>

              <half-donut-skill
                :id="'player_' + s_id"
                :data="getDataForHalfDonut(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col
            class="text-center p-0 mb-2"
            v-for="s_id in [227, 228, 229, 232]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </p>

              <half-donut-skill
                :id="'player_' + s_id"
                :data="getDataNormForHalfDonut(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="marking"
                :width="300"
                :height="150"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      71,
                      72,
                      73,
                      78,
                      74,
                      76,
                      53,
                      75,
                      225,
                      226,
                      230,
                      58,
                      55,
                      231,
                      233,
                      227,
                      228,
                      229,
                      232,
                      285,
                      286,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-3>
                SIMILAR PLAYERS
              </h2>
              <b-collapse id="collapse-micro-3">
                <similar-players
                  :skills="skills"
                  :skill_ids="[
                    71,
                    72,
                    73,
                    78,
                    74,
                    76,
                    53,
                    75,
                    225,
                    226,
                    230,
                    58,
                    55,
                    231,
                    233,
                    227,
                    228,
                    229,
                    232,
                    285,
                    286,
                  ]"
                  :players="
                    getGiocatoriSimili([
                      71,
                      72,
                      73,
                      78,
                      74,
                      76,
                      53,
                      75,
                      225,
                      226,
                      230,
                      58,
                      55,
                      231,
                      233,
                      227,
                      228,
                      229,
                      232,
                      285,
                      286,
                    ])
                  "
                >
                </similar-players>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            v-b-toggle.collapse-4
            no-body
            :class="
              'm-1 h-100 text-center ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">BUILD UP</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-4">
        <b-row align-v="center">
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Come esce dal pressing avversario?"
                    : "How do you get out of pressing opponent?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{
                  ita
                    ? "Capacità di uscita dal Pressing (oppda totale)"
                    : "Exit from Pressing"
                }}
              </h6>
            </div>
          </b-col>
          <b-col cols="2" class="text-right">
            <h6>
              Volatility:
              {{
                getMediaVolatility([
                  23,
                  25,
                  31,
                  19,
                  35,
                  18,
                  32,
                  22,
                  37,
                  33,
                  152,
                  153,
                  154,
                  155,
                  156,
                  157,
                  158,
                  159,
                  160,
                  161,
                  162,
                  163,
                  164,
                  165,
                  166,
                  167,
                ])
              }}%
            </h6>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [23, 25, 31, 35, 32, 22, 37, 33]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <vertical-bar-skill
                :id="'build_' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="4">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(19).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(19, getMediaSkillById(19))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(19) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(19, getValueSkillById(19))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(19) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(19) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(19).length > 0"
                :id="'rank' + 19"
                :data="ranking_data(19)"
              />
            </b-card>
          </b-col>
          <b-col class="p-0 mb-2" cols="2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(157).label }}
              </div>
              <vertical-bar-skill
                :id="'build_' + 157"
                :data="getDataSkillById(157)"
                :max="getMaxValueSkillById(157)"
                :min="getMinValueSkillById(157)"
                :media="getMediaSkillById(157)"
              />
            </b-card>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="4">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(18).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(18, getMediaSkillById(18))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(18) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(19, getValueSkillById(18))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(18) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(18) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(18).length > 0"
                :id="'rank' + 18"
                :data="ranking_data(18)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [152, 153, 154, 155, 156, 158, 161, 167]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <vertical-bar-skill
                :id="'build_' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col
            class="text-center p-0 mb-2"
            v-for="s_id in [159, 160, 161, 162]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </p>

              <half-donut-skill
                :id="'player_' + s_id"
                :data="getDataNormForHalfDonut(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(163).label }}
              </p>

              <half-donut-skill
                :id="'player_' + 163"
                :data="getDataForHalfDonut(163)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(164).label }}
              </p>

              <half-donut-skill
                :id="'player_' + 164"
                :data="getDataNormForHalfDonut(164)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(165).label }}
              </p>

              <half-donut-skill
                :id="'player_' + 165"
                :data="getDataForHalfDonut(165)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(166).label }}
              </p>

              <half-donut-skill
                :id="'player_' + 166"
                :data="getDataNormForHalfDonut(166)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="buildup"
                :width="300"
                :height="180"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      23,
                      25,
                      31,
                      19,
                      35,
                      18,
                      32,
                      22,
                      37,
                      33,
                      152,
                      153,
                      154,
                      155,
                      156,
                      157,
                      158,
                      159,
                      160,
                      161,
                      162,
                      163,
                      164,
                      165,
                      166,
                      167,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-4>
                SIMILAR PLAYERS
              </h2>
              <b-collapse id="collapse-micro-4">
                <similar-players
                  :skills="skills"
                  :skill_ids="[
                    23,
                    25,
                    31,
                    19,
                    35,
                    18,
                    32,
                    22,
                    37,
                    33,
                    152,
                    153,
                    154,
                    155,
                    156,
                    157,
                    158,
                    159,
                    160,
                    161,
                    162,
                    163,
                    164,
                    165,
                    166,
                    167,
                  ]"
                  :players="
                    getGiocatoriSimili([
                      23,
                      25,
                      31,
                      19,
                      35,
                      18,
                      32,
                      22,
                      37,
                      33,
                      152,
                      153,
                      154,
                      155,
                      156,
                      157,
                      158,
                      159,
                      160,
                      161,
                      162,
                      163,
                      164,
                      165,
                      166,
                      167,
                    ])
                  "
                >
                </similar-players>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            v-b-toggle.collapse-5
            no-body
            :class="
              'm-1 h-100 text-center ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">POSSESSION</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-5">
        <b-row align-v="center">
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Come gestisce il pallone?"
                    : "How do you master ball possession?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{ ita ? "Mantenimento" : "Maintenance" }}
              </h6>
            </div>
          </b-col>
          <b-col cols="2" class="text-right">
            <h6>
              Volatility:
              {{
                getMediaVolatility([
                  84,
                  80,
                  30,
                  88,
                  34,
                  13,
                  86,
                  87,
                  20,
                  246,
                  247,
                  248,
                  249,
                  250,
                  251,
                  252,
                  253,
                  254,
                  255,
                  256,
                  257,
                  258,
                  259,
                  260,
                ])
              }}%
            </h6>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(84).label }}
              </p>

              <half-donut-skill
                :id="'player_' + 84"
                :data="getDataNormForHalfDonut(84)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(260).label }}
              </p>

              <half-donut-skill
                :id="'player_' + 260"
                :data="getDataForHalfDonut(260)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(80).label }}
              </p>

              <half-donut-skill
                :id="'player_' + 80"
                :data="getDataForHalfDonut(80)"
              />
            </b-card>
          </b-col>
          <b-col class="text-center p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(30).label }}
              </p>

              <half-donut-skill
                :id="'player_' + 30"
                :data="getDataNormForHalfDonut(30)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col
            class="text-center p-0 mb-2"
            v-for="s_id in [88, 34, 86, 87]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </p>

              <half-donut-skill
                :id="'player_' + s_id"
                :data="getDataForHalfDonut(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2" v-for="s_id in [20, 13]" :key="'col-' + s_id">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="4">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' +
                          getBgStat(s_id, getMediaSkillById(s_id))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' +
                          getBgStat(s_id, getValueSkillById(s_id))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(s_id) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(s_id).length > 0"
                :id="'rank' + s_id"
                :data="ranking_data(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [246, 247, 248, 249, 250, 251, 252, 253]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <vertical-bar-skill
                :id="'build_' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [254, 255, 256]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <horizontal-bar-skill
                :id="'player' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [257, 258, 259]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <horizontal-bar-skill
                :id="'player' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="possession"
                :width="300"
                :height="180"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      84,
                      80,
                      30,
                      88,
                      34,
                      13,
                      86,
                      87,
                      20,
                      246,
                      247,
                      248,
                      249,
                      250,
                      251,
                      252,
                      253,
                      254,
                      255,
                      256,
                      257,
                      258,
                      259,
                      260,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-5>
                SIMILAR PLAYERS
              </h2>
              <b-collapse id="collapse-micro-5">
                <similar-players
                  :skills="skills"
                  :skill_ids="[
                    84,
                    80,
                    30,
                    88,
                    34,
                    13,
                    86,
                    87,
                    20,
                    246,
                    247,
                    248,
                    249,
                    250,
                    251,
                    252,
                    253,
                    254,
                    255,
                    256,
                    257,
                    258,
                    259,
                    260,
                  ]"
                  :players="
                    getGiocatoriSimili([
                      84,
                      80,
                      30,
                      88,
                      34,
                      13,
                      86,
                      87,
                      20,
                      246,
                      247,
                      248,
                      249,
                      250,
                      251,
                      252,
                      253,
                      254,
                      255,
                      256,
                      257,
                      258,
                      259,
                      260,
                    ])
                  "
                >
                </similar-players>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            v-b-toggle.collapse-6
            no-body
            :class="
              'm-1 h-100 text-center ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">ENGAGEMENT</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-6">
        <b-row align-v="center">
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Coinvolgimento nelle dinamiche gioco"
                    : "Engagement in game Dynamics"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{ ita ? "Partecipazione al gioco" : "Play Engagement" }}
              </h6>
            </div>
          </b-col>
          <b-col cols="2" class="text-right">
            <h6>
              Volatility:
              {{
                getMediaVolatility([
                  12,
                  16,
                  17,
                  67,
                  38,
                  39,
                  95,
                  36,
                  21,
                  93,
                  185,
                  186,
                  187,
                  188,
                  189,
                  190,
                  191,
                  192,
                  193,
                  194,
                ])
              }}%
            </h6>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [12, 16, 17]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="4">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' +
                          getBgStat(s_id, getMediaSkillById(s_id))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' +
                          getBgStat(s_id, getValueSkillById(s_id))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(s_id) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(s_id).length > 0"
                :id="'rank' + s_id"
                :data="ranking_data(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">{{ getSkillById(38).label }}</p>

              <half-donut-skill
                :id="'player_' + 38"
                :data="getDataNormForHalfDonut(38)"
              />
            </b-card>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="4">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(67).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(67, getMediaSkillById(67))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(67) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(67, getValueSkillById(67))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(67) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(67) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(67).length > 0"
                :id="'rank' + 67"
                :data="ranking_data(67)"
              />
            </b-card>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <p style="font-size: 0.85rem">{{ getSkillById(39).label }}</p>
              <half-donut-skill
                :id="'player_' + 39"
                :data="getDataNormForHalfDonut(39)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [95, 36, 21]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="4">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' +
                          getBgStat(s_id, getMediaSkillById(s_id))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' +
                          getBgStat(s_id, getValueSkillById(s_id))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(s_id) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(s_id).length > 0"
                :id="'rank' + s_id"
                :data="ranking_data(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [93, 185, 186, 187, 188, 189, 190, 191]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <vertical-bar-skill
                :id="'build_' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [192, 193, 194]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <horizontal-bar-skill
                :id="'player' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="engagement"
                :width="300"
                :height="150"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      12,
                      16,
                      17,
                      67,
                      38,
                      39,
                      95,
                      36,
                      21,
                      93,
                      185,
                      186,
                      187,
                      188,
                      189,
                      190,
                      191,
                      192,
                      193,
                      194,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-6>
                SIMILAR PLAYERS
              </h2>
              <b-collapse id="collapse-micro-6">
                <similar-players
                  :skills="skills"
                  :skill_ids="[
                    12,
                    16,
                    17,
                    67,
                    38,
                    39,
                    95,
                    36,
                    21,
                    93,
                    185,
                    186,
                    187,
                    188,
                    189,
                    190,
                    191,
                    192,
                    193,
                    194,
                  ]"
                  :players="
                    getGiocatoriSimili([
                      12,
                      16,
                      17,
                      67,
                      38,
                      39,
                      95,
                      36,
                      21,
                      93,
                      185,
                      186,
                      187,
                      188,
                      189,
                      190,
                      191,
                      192,
                      193,
                      194,
                    ])
                  "
                >
                </similar-players>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-7
            :class="
              'm-1 h-100 text-center ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">UNMARKING</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-7">
        <b-row align-v="center">
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Dove riceve il maggior numero di palloni?"
                    : "Where do you get the most balls?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
              <h6 class="card-subtitle text-muted">
                {{ ita ? "Smarcamenti" : "Unmarking" }}
              </h6>
            </div>
          </b-col>
          <b-col cols="2" class="text-right">
            <h6>
              Volatility:
              {{
                getMediaVolatility([26, 27, 28, 29, 280, 281, 282, 283, 284])
              }}%
            </h6>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="5">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(28).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(28, getMediaSkillById(28))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(28) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(28, getValueSkillById(28))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(28) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(28) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(28).length > 0"
                :id="'rank' + 28"
                :data="ranking_data(28)"
              />
            </b-card>
          </b-col>
          <b-col cols="3" class="p-0 mb-2">
            <b-row class="h-50">
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(26).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 26"
                    :data="getDataNormForHalfDonut(26)"
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row class="h-50">
              <b-col class="pb-0">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(27).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 27"
                    :data="getDataNormForHalfDonut(27)"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="5">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(29).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(29, getMediaSkillById(29))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(29) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(29, getValueSkillById(29))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(29) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(29) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(29).length > 0"
                :id="'rank' + 29"
                :data="ranking_data(29)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [280, 281, 282, 283, 284]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <vertical-bar-skill
                :id="'build_' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="unmarking"
                :width="300"
                :height="80"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [26, 27, 28, 29, 280, 281, 282, 283, 284].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-7>
                SIMILAR PLAYERS
              </h2>
              <b-collapse id="collapse-micro-7">
                <similar-players
                  :skills="skills"
                  :skill_ids="[26, 27, 28, 29, 280, 281, 282, 283, 284]"
                  :players="
                    getGiocatoriSimili([
                      26,
                      27,
                      28,
                      29,
                      280,
                      281,
                      282,
                      283,
                      284,
                    ])
                  "
                >
                </similar-players>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-8
            :class="
              'm-1 h-100 text-center ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">DRIBBLING</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-8">
        <b-row align-v="center">
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{ ita ? "Capacità di saltare l’uomo" : "Dribbling skills" }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
            </div>
          </b-col>
          <b-col cols="2" class="text-right">
            <h6>
              Volatility:
              {{
                getMediaVolatility([
                  45,
                  46,
                  47,
                  91,
                  90,
                  174,
                  175,
                  176,
                  177,
                  178,
                  179,
                  180,
                  181,
                  182,
                  183,
                  184,
                ])
              }}%
            </h6>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="5">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(45).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(45, getMediaSkillById(45))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(45) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(45, getValueSkillById(45))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(45) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(45) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(45).length > 0"
                :id="'rank' + 45"
                :data="ranking_data(45)"
              />
            </b-card>
          </b-col>
          <b-col cols="3" class="p-0 mb-2">
            <b-row class="h-50">
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(46).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 46"
                    :data="getDataNormForHalfDonut(46)"
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row class="h-50">
              <b-col class="pb-0">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(91).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 91"
                    :data="getDataNormForHalfDonut(91)"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="5">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(47).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(47, getMediaSkillById(47))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(47) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(47, getValueSkillById(47))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(47) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(47) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(47).length > 0"
                :id="'rank' + 47"
                :data="ranking_data(47)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [90, 174, 175, 176]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <horizontal-bar-skill
                :id="'player' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [177, 178, 179, 180, 181, 182, 183, 184]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <vertical-bar-skill
                :id="'build_' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="dribbling"
                :width="300"
                :height="130"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      45,
                      46,
                      47,
                      91,
                      90,
                      174,
                      175,
                      176,
                      177,
                      178,
                      179,
                      180,
                      181,
                      182,
                      183,
                      184,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-8>
                SIMILAR PLAYERS
              </h2>
              <b-collapse id="collapse-micro-8">
                <similar-players
                  :skills="skills"
                  :skill_ids="[
                    45,
                    46,
                    47,
                    91,
                    90,
                    174,
                    175,
                    176,
                    177,
                    178,
                    179,
                    180,
                    181,
                    182,
                    183,
                    184,
                  ]"
                  :players="
                    getGiocatoriSimili([
                      45,
                      46,
                      47,
                      91,
                      90,
                      174,
                      175,
                      176,
                      177,
                      178,
                      179,
                      180,
                      181,
                      182,
                      183,
                      184,
                    ])
                  "
                >
                </similar-players>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-9
            :class="
              'm-1 h-100 text-center ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">WIDE PLAY</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-9">
        <b-row align-v="center">
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Che tipologia di cross preferisce?"
                    : "What kind of cross do you prefer?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
            </div>
          </b-col>
          <b-col cols="2" class="text-right">
            <h6>
              Volatility:
              {{
                getMediaVolatility([
                  62,
                  63,
                  102,
                  103,
                  81,
                  143,
                  144,
                  145,
                  146,
                  147,
                  148,
                  149,
                  150,
                  151,
                  195,
                  196,
                  197,
                  198,
                  199,
                  200,
                  201,
                  202,
                  203,
                  204,
                  205,
                  206,
                ])
              }}%
            </h6>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [62, 63, 102, 103, 81]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <vertical-bar-skill
                :id="'wp_' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div>
                {{ getSkillById(143).label }} / {{ getSkillById(144).label }} /
                {{ getSkillById(145).label }}
              </div>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'left:11%; top: 15%; background:' +
                      getBgStat(145, getValueSkillById(145))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(145) }}
                  </div>
                  <div
                    :style="
                      'left:13%; top: 21%; background:' +
                      getBgStat(145, getMediaSkillById(145))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(145) }}
                  </div>
                  <div
                    :style="
                      'left:11%; top: 28%; background:' +
                      getBgStat(143, getValueSkillById(143))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(143) }}
                  </div>
                  <div
                    :style="
                      'left:13%; top: 34%; background:' +
                      getBgStat(143, getMediaSkillById(143))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(143) }}
                  </div>

                  <div
                    :style="
                      'left:11%; top: 50%; background:' +
                      getBgStat(144, getValueSkillById(144))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(144) }}
                  </div>
                  <div
                    :style="
                      'left:13%; top: 56%; background:' +
                      getBgStat(144, getMediaSkillById(144))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(144) }}
                  </div>
                  <dashed-field />
                </b-col>
                <!--b-col cols="2"></b-col-->
              </b-row>
            </b-card>
          </b-col>

          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div>
                {{ getSkillById(146).label }} / {{ getSkillById(147).label }} /
                {{ getSkillById(148).label }}
              </div>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'left:11%; top: 15%; background:' +
                      getBgStat(148, getValueSkillById(148))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(148) }}
                  </div>
                  <div
                    :style="
                      'left:13%; top: 21%; background:' +
                      getBgStat(148, getMediaSkillById(148))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(148) }}
                  </div>
                  <div
                    :style="
                      'left:11%; top: 28%; background:' +
                      getBgStat(146, getValueSkillById(146))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(146) }}
                  </div>
                  <div
                    :style="
                      'left:13%; top: 34%; background:' +
                      getBgStat(146, getMediaSkillById(146))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(146) }}
                  </div>

                  <div
                    :style="
                      'left:11%; top: 50%; background:' +
                      getBgStat(147, getValueSkillById(147))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(147) }}
                  </div>
                  <div
                    :style="
                      'left:13%; top: 56%; background:' +
                      getBgStat(147, getMediaSkillById(147))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(147) }}
                  </div>
                  <dashed-field />
                </b-col>
                <!--b-col cols="2"></b-col-->
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="2"> </b-col>
                <b-col>
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(150).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 150"
                    :data="getDataNormForHalfDonut(150)"
                  />
                </b-col>
                <b-col cols="2"> </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="2"> </b-col>
                <b-col>
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(149).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 149"
                    :data="getDataForHalfDonut(149)"
                  />
                </b-col>
                <b-col cols="2"> </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="2"> </b-col>
                <b-col>
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(151).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 151"
                    :data="getDataNormForHalfDonut(151)"
                  />
                </b-col>
                <b-col cols="2"> </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [195, 196, 197, 198, 199, 200]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <vertical-bar-skill
                :id="'wp_' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [201, 202, 203, 204, 205, 206]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <vertical-bar-skill
                :id="'wp_' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="wp"
                :width="300"
                :height="180"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      62,
                      63,
                      102,
                      103,
                      81,
                      143,
                      144,
                      145,
                      146,
                      147,
                      148,
                      149,
                      150,
                      151,
                      195,
                      196,
                      197,
                      198,
                      199,
                      200,
                      201,
                      202,
                      203,
                      204,
                      205,
                      206,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-9>
                SIMILAR PLAYERS
              </h2>
              <b-collapse id="collapse-micro-9">
                <similar-players
                  :skills="skills"
                  :skill_ids="[
                    62,
                    63,
                    102,
                    103,
                    81,
                    143,
                    144,
                    145,
                    146,
                    147,
                    148,
                    149,
                    150,
                    151,
                    195,
                    196,
                    197,
                    198,
                    199,
                    200,
                    201,
                    202,
                    203,
                    204,
                    205,
                    206,
                  ]"
                  :players="
                    getGiocatoriSimili([
                      62,
                      63,
                      102,
                      103,
                      81,
                      143,
                      144,
                      145,
                      146,
                      147,
                      148,
                      149,
                      150,
                      151,
                      195,
                      196,
                      197,
                      198,
                      199,
                      200,
                      201,
                      202,
                      203,
                      204,
                      205,
                      206,
                    ])
                  "
                >
                </similar-players>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-10
            :class="
              'm-1 h-100 text-center ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">FINISHING</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-10">
        <b-row align-v="center">
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Quanto è incisivo nella finalizzazione?"
                    : "How incisive is he in finalizing?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
            </div>
          </b-col>
          <b-col cols="2" class="text-right">
            <h6>
              Volatility:
              {{
                getMediaVolatility([
                  4,
                  5,
                  42,
                  43,
                  44,
                  64,
                  65,
                  92,
                  104,
                  105,
                  106,
                  108,
                  207,
                  208,
                  209,
                  210,
                  211,
                  212,
                  213,
                  214,
                  215,
                  216,
                  217,
                  218,
                  219,
                  220,
                  221,
                  222,
                  223,
                  224,
                ])
              }}%
            </h6>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2" v-for="s_id in [4, 104]" :key="'col-' + s_id">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col>
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' +
                          getBgStat(s_id, getMediaSkillById(s_id))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' +
                          getBgStat(s_id, getValueSkillById(s_id))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(s_id) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(s_id).length > 0"
                :id="'rank' + s_id"
                :data="ranking_data(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2" v-for="s_id in [5, 105]" :key="'col-' + s_id">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="4">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' +
                          getBgStat(s_id, getMediaSkillById(s_id))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' +
                          getBgStat(s_id, getValueSkillById(s_id))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(s_id) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(s_id).length > 0"
                :id="'rank' + s_id"
                :data="ranking_data(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [106, 108, 207]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="4">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' +
                          getBgStat(s_id, getMediaSkillById(s_id))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' +
                          getBgStat(s_id, getValueSkillById(s_id))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(s_id) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(s_id).length > 0"
                :id="'rank' + s_id"
                :data="ranking_data(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="5">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(43).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(43, getMediaSkillById(43))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(43) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(43, getValueSkillById(43))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(43) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(43) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(43).length > 0"
                :id="'rank' + 43"
                :data="ranking_data(43)"
              />
            </b-card>
          </b-col>
          <b-col cols="3" class="p-0 mb-2">
            <b-row class="h-25">
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(42).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 42"
                    :data="getDataNormForHalfDonut(42)"
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row class="h-25">
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(65).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 65"
                    :data="getDataForHalfDonut(65)"
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row class="h-25">
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(44).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 44"
                    :data="getDataForHalfDonut(44)"
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row class="h-25">
              <b-col class="pb-0">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(92).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 92"
                    :data="getDataNormForHalfDonut(92)"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="5">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(64).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(64, getMediaSkillById(64))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(64) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(64, getValueSkillById(64))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(64) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(64) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(64).length > 0"
                :id="'rank' + 64"
                :data="ranking_data(64)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div>
                {{ getSkillById(208).label }} / {{ getSkillById(209).label }}
              </div>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'left:30%; top: 48%; background:' +
                      getBgStat(209, getValueSkillById(209))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(209) }}
                  </div>
                  <div
                    :style="
                      'left:32%; top: 56%; background:' +
                      getBgStat(209, getMediaSkillById(209))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(209) }}
                  </div>
                  <div
                    :style="
                      'left:63%; top: 48%; background:' +
                      getBgStat(208, getValueSkillById(208))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(208) }}
                  </div>
                  <div
                    :style="
                      'left:65%; top: 56%; background:' +
                      getBgStat(208, getMediaSkillById(208))
                    "
                    class="zona-campo"
                  >
                    {{ getMediaSkillById(208) }}
                  </div>
                  <div
                    style="
                      position: absolute;
                      left: 19%;
                      top: 85%;
                      color: #ffffff;
                      font-size: 1.5rem;
                      font-weight: bold;
                    "
                  >
                    {{ getRankSkill(209) }}
                  </div>
                  <div
                    style="
                      position: absolute;
                      left: 62%;
                      top: 71%;
                      color: #ffffff;
                      font-size: 1.5rem;
                      font-weight: bold;
                    "
                  >
                    {{ getRankSkill(208) }}
                  </div>
                  <b-img :src="halfFieldRight" fluid></b-img>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col class="p-0 mb-2 text-center">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div>
                {{ getSkillById(216).label }} / {{ getSkillById(217).label }} /
                {{ getSkillById(215).label }} / {{ getSkillById(214).label }}
              </div>
              <b-row>
                <b-col>
                  <div
                    :style="
                      'left:11%; top: 15%; background:' +
                      getBgStat(215, getValueSkillById(215))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(215) }}
                  </div>
                  <div
                    :style="
                      'left:11%; top: 28%; background:' +
                      getBgStat(216, getValueSkillById(216))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(216) }}
                  </div>

                  <div
                    :style="
                      'left:11%; top: 50%; background:' +
                      getBgStat(217, getValueSkillById(217))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(217) }}
                  </div>

                  <div
                    :style="
                      'left:43%; top: 50%; background:' +
                      getBgStat(214, getValueSkillById(214))
                    "
                    class="zona-campo-large"
                  >
                    {{ getValueSkillById(214) }}
                  </div>
                  <dashed-field />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [210, 211, 212, 213]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="2"> </b-col>
                <b-col>
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + s_id"
                    :data="getDataNormForHalfDonut(s_id)"
                  />
                </b-col>
                <b-col cols="2"> </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [218, 219, 220, 221, 222, 223, 224]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <vertical-bar-skill
                :id="'wp_' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="finishing"
                :width="300"
                :height="200"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      4,
                      5,
                      42,
                      43,
                      44,
                      64,
                      65,
                      92,
                      104,
                      105,
                      106,
                      108,
                      207,
                      208,
                      209,
                      210,
                      211,
                      212,
                      213,
                      214,
                      215,
                      216,
                      217,
                      218,
                      219,
                      220,
                      221,
                      222,
                      223,
                      224,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-10>
                SIMILAR PLAYERS
              </h2>
              <b-collapse id="collapse-micro-10">
                <similar-players
                  :skills="skills"
                  :skill_ids="[
                    4,
                    5,
                    42,
                    43,
                    44,
                    64,
                    65,
                    92,
                    104,
                    105,
                    106,
                    108,
                    207,
                    208,
                    209,
                    210,
                    211,
                    212,
                    213,
                    214,
                    215,
                    216,
                    217,
                    218,
                    219,
                    220,
                    221,
                    222,
                    223,
                    224,
                  ]"
                  :players="
                    getGiocatoriSimili([
                      4,
                      5,
                      42,
                      43,
                      44,
                      64,
                      65,
                      92,
                      104,
                      105,
                      106,
                      108,
                      207,
                      208,
                      209,
                      210,
                      211,
                      212,
                      213,
                      214,
                      215,
                      216,
                      217,
                      218,
                      219,
                      220,
                      221,
                      222,
                      223,
                      224,
                    ])
                  "
                >
                </similar-players>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-11
            :class="
              'm-1 h-100 text-center ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">CREATIVITY</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-11">
        <b-row align-v="center">
          <b-col class="p-0 mb-2">
            <div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? "Quanto è incisivo nella rifinitura e creatività?"
                    : "How incisive is he in assists and creativity?"
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
            </div>
          </b-col>
          <b-col cols="2" class="text-right">
            <h6>
              Volatility:
              {{
                getMediaVolatility([
                  6,
                  107,
                  7,
                  8,
                  9,
                  10,
                  40,
                  41,
                  89,
                  168,
                  169,
                  170,
                  171,
                  172,
                  173,
                ])
              }}%
            </h6>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [6, 107, 7]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="4">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' +
                          getBgStat(s_id, getMediaSkillById(s_id))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' +
                          getBgStat(s_id, getValueSkillById(s_id))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(s_id) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(s_id).length > 0"
                :id="'rank' + s_id"
                :data="ranking_data(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="5">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(9).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(9, getMediaSkillById(9))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(9) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(9, getValueSkillById(9))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(9) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(9) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(9).length > 0"
                :id="'rank' + 9"
                :data="ranking_data(9)"
              />
            </b-card>
          </b-col>
          <b-col cols="3" class="p-0 mb-2">
            <b-row class="h-25">
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(8).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 8"
                    :data="getDataNormForHalfDonut(8)"
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row class="h-25">
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(171).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 171"
                    :data="getDataNormForHalfDonut(171)"
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row class="h-25">
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(172).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 172"
                    :data="getDataNormForHalfDonut(172)"
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row class="h-25">
              <b-col class="pb-0">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(173).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 173"
                    :data="getDataNormForHalfDonut(173)"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="5">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(10).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(10, getMediaSkillById(10))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(10) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(10, getValueSkillById(10))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(10) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(10) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(10).length > 0"
                :id="'rank' + 10"
                :data="ranking_data(10)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="5">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(41).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(41, getMediaSkillById(41))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(41) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(41, getValueSkillById(41))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(41) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(41) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(41).length > 0"
                :id="'rank' + 41"
                :data="ranking_data(41)"
              />
            </b-card>
          </b-col>
          <b-col cols="3" class="p-0 mb-2">
            <b-row class="h-25">
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(40).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 40"
                    :data="getDataNormForHalfDonut(40)"
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row class="h-25">
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(169).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 169"
                    :data="getDataNormForHalfDonut(169)"
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row class="h-25">
              <b-col class="pb-2">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(170).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 170"
                    :data="getDataNormForHalfDonut(170)"
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row class="h-25">
              <b-col class="pb-0">
                <b-card
                  :class="
                    'm-1 h-100 text-center ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(168).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + 168"
                    :data="getDataNormForHalfDonut(168)"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="5">
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(89).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(89, getMediaSkillById(89))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(89) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(89, getValueSkillById(89))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(89) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(89) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(89).length > 0"
                :id="'rank' + 89"
                :data="ranking_data(89)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="creativity"
                :width="300"
                :height="120"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      6,
                      107,
                      7,
                      8,
                      9,
                      10,
                      40,
                      41,
                      89,
                      168,
                      169,
                      170,
                      171,
                      172,
                      173,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-11>
                SIMILAR PLAYERS
              </h2>
              <b-collapse id="collapse-micro-11">
                <similar-players
                  :skills="skills"
                  :skill_ids="[
                    6,
                    107,
                    7,
                    8,
                    9,
                    10,
                    40,
                    41,
                    89,
                    168,
                    169,
                    170,
                    171,
                    172,
                    173,
                  ]"
                  :players="
                    getGiocatoriSimili([
                      6,
                      107,
                      7,
                      8,
                      9,
                      10,
                      40,
                      41,
                      89,
                      168,
                      169,
                      170,
                      171,
                      172,
                      173,
                    ])
                  "
                >
                </similar-players>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <div v-if="player && player.ruolo_id == 6">
        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              v-b-toggle.collapse-15
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1">GOALKEEPER</h2>
            </b-card>
          </b-col>
        </b-row>

        <b-collapse visible id="collapse-11">
          <b-row align-v="center">
            <b-col class="p-0 mb-2">
              <div class="card-body pb-1">
                <h4 class="card-title">
                  {{ ita ? "Goalkeeper features" : "Caratteristiche portiere" }}
                  <span style="cursor: pointer" @click="ita = !ita">
                    <flag
                      v-if="!ita"
                      country="IT"
                      :size="16"
                      class="ml-1"
                      title="IT"
                      style="cursor: pointer"
                    ></flag>
                    <flag
                      v-if="ita"
                      country="GB"
                      :size="16"
                      class="ml-1"
                      title="EN"
                    ></flag>
                  </span>
                </h4>
              </div>
            </b-col>
            <b-col cols="2" class="text-right">
              <h6>
                Volatility:
                {{
                  getMediaVolatility([
                    126,
                    127,
                    128,
                    129,
                    130,
                    131,
                    132,
                    133,
                    134,
                    135,
                    136,
                    137,
                    138,
                    139,
                    140,
                    141,
                    142,
                  ])
                }}%
              </h6>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="p-0 mb-2">
              <b-card
                :class="
                  'm-1 h-100 ' +
                  (player.giocatore && player.giocatore.sfondo
                    ? 'card-bg-opacity'
                    : '')
                "
              >
                <b-row>
                  <b-col cols="4">
                    <div style="font-size: 0.85rem">
                      {{ getSkillById(126).label }}
                    </div>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                      <b-col class="pl-0">
                        <div
                          title="media"
                          :style="
                            'background:' +
                            getBgStat(126, getMediaSkillById(126))
                          "
                          class="box-small"
                        >
                          {{ getMediaSkillById(126) }}
                        </div>
                      </b-col>
                      <b-col>
                        <div
                          title="player"
                          :style="
                            'background:' +
                            getBgStat(126, getValueSkillById(126))
                          "
                          class="box-large"
                        >
                          {{ getValueSkillById(126) }}
                        </div>
                      </b-col>
                      <b-col class="px-0">
                        <div
                          title="rank"
                          style="font-weight: bold; font-size: 1rem"
                        >
                          {{ getRankSkill(126) }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <horizontal-bar-ranking
                  v-if="ranking_data(126).length > 0"
                  :id="'rank' + 126"
                  :data="ranking_data(126)"
                />
              </b-card>
            </b-col>
            <b-col cols="3" class="p-0 mb-2">
              <b-row class="h-25">
                <b-col class="pb-2">
                  <b-card
                    :class="
                      'm-1 h-100 text-center ' +
                      (player.giocatore && player.giocatore.sfondo
                        ? 'card-bg-opacity'
                        : '')
                    "
                  >
                    <p style="font-size: 0.85rem">
                      {{ getSkillById(128).label }}
                    </p>
                    <half-donut-skill
                      :id="'player_' + 128"
                      :data="getDataNormForHalfDonut(128)"
                    />
                  </b-card>
                </b-col>
              </b-row>
              <b-row class="h-25">
                <b-col class="pb-2">
                  <b-card
                    :class="
                      'm-1 h-100 text-center ' +
                      (player.giocatore && player.giocatore.sfondo
                        ? 'card-bg-opacity'
                        : '')
                    "
                  >
                    <p style="font-size: 0.85rem">
                      {{ getSkillById(127).label }}
                    </p>
                    <half-donut-skill
                      :id="'player_' + 127"
                      :data="getDataNormForHalfDonut(127)"
                    />
                  </b-card>
                </b-col>
              </b-row>
              <b-row class="h-25">
                <b-col class="pb-2">
                  <b-card
                    :class="
                      'm-1 h-100 text-center ' +
                      (player.giocatore && player.giocatore.sfondo
                        ? 'card-bg-opacity'
                        : '')
                    "
                  >
                    <p style="font-size: 0.85rem">
                      {{ getSkillById(130).label }}
                    </p>
                    <half-donut-skill
                      :id="'player_' + 130"
                      :data="getDataNormForHalfDonut(130)"
                    />
                  </b-card>
                </b-col>
              </b-row>
              <b-row class="h-25">
                <b-col>
                  <b-card
                    :class="
                      'm-1 h-100 text-center ' +
                      (player.giocatore && player.giocatore.sfondo
                        ? 'card-bg-opacity'
                        : '')
                    "
                  >
                    <p style="font-size: 0.85rem">
                      {{ getSkillById(135).label }}
                    </p>
                    <half-donut-skill
                      :id="'player_' + 135"
                      :data="getDataNormForHalfDonut(135)"
                    />
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="p-0 mb-2">
              <b-card
                :class="
                  'm-1 h-100 ' +
                  (player.giocatore && player.giocatore.sfondo
                    ? 'card-bg-opacity'
                    : '')
                "
              >
                <b-row>
                  <b-col cols="4">
                    <div style="font-size: 0.85rem">
                      {{ getSkillById(129).label }}
                    </div>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                      <b-col class="pl-0">
                        <div
                          title="media"
                          :style="
                            'background:' +
                            getBgStat(129, getMediaSkillById(129))
                          "
                          class="box-small"
                        >
                          {{ getMediaSkillById(129) }}
                        </div>
                      </b-col>
                      <b-col>
                        <div
                          title="player"
                          :style="
                            'background:' +
                            getBgStat(129, getValueSkillById(129))
                          "
                          class="box-large"
                        >
                          {{ getValueSkillById(129) }}
                        </div>
                      </b-col>
                      <b-col class="px-0">
                        <div
                          title="rank"
                          style="font-weight: bold; font-size: 1rem"
                        >
                          {{ getRankSkill(129) }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <horizontal-bar-ranking
                  v-if="ranking_data(129).length > 0"
                  :id="'rank' + 129"
                  :data="ranking_data(129)"
                />
              </b-card>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="p-0 mb-2">
              <b-card
                :class="
                  'm-1 h-100 ' +
                  (player.giocatore && player.giocatore.sfondo
                    ? 'card-bg-opacity'
                    : '')
                "
              >
                <b-row>
                  <b-col cols="5">
                    <div style="font-size: 0.85rem">
                      {{ getSkillById(131).label }}
                    </div>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                      <b-col class="pl-0">
                        <div
                          title="media"
                          :style="
                            'background:' +
                            getBgStat(131, getMediaSkillById(131))
                          "
                          class="box-small"
                        >
                          {{ getMediaSkillById(131) }}
                        </div>
                      </b-col>
                      <b-col>
                        <div
                          title="player"
                          :style="
                            'background:' +
                            getBgStat(131, getValueSkillById(131))
                          "
                          class="box-large"
                        >
                          {{ getValueSkillById(131) }}
                        </div>
                      </b-col>
                      <b-col class="px-0">
                        <div
                          title="rank"
                          style="font-weight: bold; font-size: 1rem"
                        >
                          {{ getRankSkill(131) }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <horizontal-bar-ranking
                  v-if="ranking_data(131).length > 0"
                  :id="'rank' + 131"
                  :data="ranking_data(131)"
                />
              </b-card>
            </b-col>
            <b-col cols="3" class="p-0 mb-2">
              <b-row class="h-33">
                <b-col class="pb-2">
                  <b-card
                    :class="
                      'm-1 h-100 text-center ' +
                      (player.giocatore && player.giocatore.sfondo
                        ? 'card-bg-opacity'
                        : '')
                    "
                  >
                    <p style="font-size: 0.85rem">
                      {{ getSkillById(133).label }}
                    </p>
                    <half-donut-skill
                      :id="'player_' + 133"
                      :data="getDataNormForHalfDonut(133)"
                    />
                  </b-card>
                </b-col>
              </b-row>
              <b-row class="h-33">
                <b-col class="pb-2">
                  <b-card
                    :class="
                      'm-1 h-100 text-center ' +
                      (player.giocatore && player.giocatore.sfondo
                        ? 'card-bg-opacity'
                        : '')
                    "
                  >
                    <p style="font-size: 0.85rem">
                      {{ getSkillById(134).label }}
                    </p>
                    <half-donut-skill
                      :id="'player_' + 134"
                      :data="getDataForHalfDonut(134)"
                    />
                  </b-card>
                </b-col>
              </b-row>
              <b-row class="h-33">
                <b-col>
                  <b-card
                    :class="
                      'm-1 h-100 text-center ' +
                      (player.giocatore && player.giocatore.sfondo
                        ? 'card-bg-opacity'
                        : '')
                    "
                  >
                    <p style="font-size: 0.85rem">
                      {{ getSkillById(136).label }}
                    </p>
                    <half-donut-skill
                      :id="'player_' + 136"
                      :data="getDataNormForHalfDonut(136)"
                    />
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="p-0 mb-2">
              <b-card
                :class="
                  'm-1 h-100 ' +
                  (player.giocatore && player.giocatore.sfondo
                    ? 'card-bg-opacity'
                    : '')
                "
              >
                <b-row>
                  <b-col cols="5">
                    <div style="font-size: 0.85rem">
                      {{ getSkillById(132).label }}
                    </div>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                      <b-col class="pl-0">
                        <div
                          title="media"
                          :style="
                            'background:' +
                            getBgStat(132, getMediaSkillById(132))
                          "
                          class="box-small"
                        >
                          {{ getMediaSkillById(132) }}
                        </div>
                      </b-col>
                      <b-col>
                        <div
                          title="player"
                          :style="
                            'background:' +
                            getBgStat(132, getValueSkillById(132))
                          "
                          class="box-large"
                        >
                          {{ getValueSkillById(132) }}
                        </div>
                      </b-col>
                      <b-col class="px-0">
                        <div
                          title="rank"
                          style="font-weight: bold; font-size: 1rem"
                        >
                          {{ getRankSkill(132) }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <horizontal-bar-ranking
                  v-if="ranking_data(132).length > 0"
                  :id="'rank' + 132"
                  :data="ranking_data(132)"
                />
              </b-card>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              class="p-0 mb-2"
              v-for="s_id in [137, 138, 139, 140, 141, 142]"
              :key="'col-' + s_id"
            >
              <b-card
                :class="
                  'm-1 h-100 ' +
                  (player.giocatore && player.giocatore.sfondo
                    ? 'card-bg-opacity'
                    : '')
                "
              >
                <div style="font-size: 0.85rem">
                  {{ getSkillById(s_id).label }}
                </div>
                <vertical-bar-skill
                  :id="'gk_' + s_id"
                  :data="getDataSkillById(s_id)"
                  :max="getMaxValueSkillById(s_id)"
                  :min="getMinValueSkillById(s_id)"
                  :media="getMediaSkillById(s_id)"
                />
              </b-card>
            </b-col>
          </b-row>

          <b-row align-h="center">
            <b-col class="p-0 mb-2">
              <b-card
                :class="
                  'm-1 h-100 ' +
                  (player.giocatore && player.giocatore.sfondo
                    ? 'card-bg-opacity'
                    : '')
                "
              >
                <dot-plot-skills
                  id="goalkeeper"
                  :width="300"
                  :height="140"
                  :left="60"
                  :data="
                    dot_plot_skills_data.filter((item) =>
                      [
                        126,
                        127,
                        128,
                        129,
                        130,
                        131,
                        132,
                        133,
                        134,
                        135,
                        136,
                        137,
                        138,
                        139,
                        140,
                        141,
                        142,
                      ].includes(item.id)
                    )
                  "
                />
              </b-card>
            </b-col>
          </b-row>

          <b-row align-h="center">
            <b-col class="p-0 mb-2">
              <b-card
                no-body
                :class="
                  'm-1 h-100 text-center ' +
                  (player.giocatore && player.giocatore.sfondo
                    ? 'card-bg-opacity'
                    : '')
                "
              >
                <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-15>
                  SIMILAR PLAYERS
                </h2>
                <b-collapse id="collapse-micro-15">
                  <similar-players
                    :skills="skills"
                    :skill_ids="[
                      126,
                      127,
                      128,
                      129,
                      130,
                      131,
                      132,
                      133,
                      134,
                      135,
                      136,
                      137,
                      138,
                      139,
                      140,
                      141,
                      142,
                    ]"
                    :players="
                      getGiocatoriSimili([
                        126,
                        127,
                        128,
                        129,
                        130,
                        131,
                        132,
                        133,
                        134,
                        135,
                        136,
                        137,
                        138,
                        139,
                        140,
                        141,
                        142,
                      ])
                    "
                  >
                  </similar-players>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
        </b-collapse>
      </div>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            v-b-toggle.collapse-14
            :class="
              'm-1 h-100 text-center ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <h2 class="mb-0 mt-1">SET PLAY</h2>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-14">
        <b-row align-v="center">
          <b-col class="p-0 mb-2">
            <!--div class="card-body pb-1">
              <h4 class="card-title">
                {{
                  ita
                    ? ""
                    : ""
                }}
                <span style="cursor: pointer" @click="ita = !ita">
                  <flag
                    v-if="!ita"
                    country="IT"
                    :size="16"
                    class="ml-1"
                    title="IT"
                    style="cursor: pointer"
                  ></flag>
                  <flag
                    v-if="ita"
                    country="GB"
                    :size="16"
                    class="ml-1"
                    title="EN"
                  ></flag>
                </span>
              </h4>
            </div-->
          </b-col>
          <b-col cols="2" class="text-right">
            <h6>
              Volatility:
              {{
                getMediaVolatility([
                  109,
                  125,
                  124,
                  123,
                  122,
                  234,
                  235,
                  236,
                  237,
                  238,
                  239,
                  240,
                  241,
                  242,
                  243,
                  244,
                  245,
                ])
              }}%
            </h6>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [124, 123]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col>
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' +
                          getBgStat(s_id, getMediaSkillById(s_id))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' +
                          getBgStat(s_id, getValueSkillById(s_id))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(s_id) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(s_id).length > 0"
                :id="'rank_' + s_id"
                :data="ranking_data(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [122, 125]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col>
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' +
                          getBgStat(s_id, getMediaSkillById(s_id))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' +
                          getBgStat(s_id, getValueSkillById(s_id))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(s_id) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(s_id).length > 0"
                :id="'rank_' + s_id"
                :data="ranking_data(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [234, 235]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col>
                  <div style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </div>
                </b-col>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' +
                          getBgStat(s_id, getMediaSkillById(s_id))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' +
                          getBgStat(s_id, getValueSkillById(s_id))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(s_id) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(s_id) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <horizontal-bar-ranking
                v-if="ranking_data(s_id).length > 0"
                :id="'rank_' + s_id"
                :data="ranking_data(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [236, 237, 238, 239]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="2"> </b-col>
                <b-col>
                  <p style="font-size: 0.85rem">
                    {{ getSkillById(s_id).label }}
                  </p>
                  <half-donut-skill
                    :id="'player_' + s_id"
                    :data="getDataNormForHalfDonut(s_id)"
                  />
                </b-col>
                <b-col cols="2"> </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="p-0 mb-2"
            v-for="s_id in [109, 240, 241, 242, 243, 244, 245]"
            :key="'col-' + s_id"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <div style="font-size: 0.85rem">
                {{ getSkillById(s_id).label }}
              </div>
              <vertical-bar-skill
                :id="'wp_' + s_id"
                :data="getDataSkillById(s_id)"
                :max="getMaxValueSkillById(s_id)"
                :min="getMinValueSkillById(s_id)"
                :media="getMediaSkillById(s_id)"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <dot-plot-skills
                id="set_play"
                :width="300"
                :height="130"
                :left="60"
                :data="
                  dot_plot_skills_data.filter((item) =>
                    [
                      109,
                      125,
                      124,
                      123,
                      122,
                      234,
                      235,
                      236,
                      237,
                      238,
                      239,
                      240,
                      241,
                      242,
                      243,
                      244,
                      245,
                    ].includes(item.id)
                  )
                "
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col class="p-0 mb-2">
            <b-card
              no-body
              :class="
                'm-1 h-100 text-center ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <h2 class="mb-0 mt-1" v-b-toggle.collapse-micro-14>
                SIMILAR PLAYERS
              </h2>
              <b-collapse id="collapse-micro-14">
                <similar-players
                  :skills="skills"
                  :skill_ids="[
                    109,
                    125,
                    124,
                    123,
                    122,
                    234,
                    235,
                    236,
                    237,
                    238,
                    239,
                    240,
                    241,
                    242,
                    243,
                    244,
                    245,
                  ]"
                  :players="
                    getGiocatoriSimili([
                      109,
                      125,
                      124,
                      123,
                      122,
                      234,
                      235,
                      236,
                      237,
                      238,
                      239,
                      240,
                      241,
                      242,
                      243,
                      244,
                      245,
                    ])
                  "
                >
                </similar-players>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row>
        <b-col class="p-0 mb-2">
          <div class="card-body pb-1">
            <h4 class="card-title">
              {{
                ita
                  ? "Impatto del giocatore per ogni skill"
                  : "Impact of the player for each skill"
              }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </h4>
          </div>
        </b-col>
        <b-col cols="3" class="p-0 mb-2 text-center">
          <div class="card-body pb-1">
            <h4 class="card-title">SWOT ANALISYS</h4>
          </div>
          <b-form-radio-group v-model="swot">
            <b-form-radio value="macro">Macro</b-form-radio>
            <b-form-radio value="micro">Micro</b-form-radio>
          </b-form-radio-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="p-0 mb-2" cols="3">
          <b-card
            :class="
              'm-1 h-100 ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <p>
              {{ ita ? "PUNTI DI FORZA" : "STRENGHTS" }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </p>
            <b-row
              align-v="center"
              v-for="item in dot_plot_skills_data_sorted_asc.slice(0, 6)"
              :key="'for' + item.id"
            >
              <b-col style="font-size: 0.85rem">{{ item.label }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item.id, getValueSkillById(item.id))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item.id) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: green; font-size: 1.1rem"
                ><strong>{{ item.rank }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
        <b-col class="p-0 mb-2" cols="3">
          <b-card
            :class="
              'm-1 h-100 ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <p>
              {{ ita ? "PUNTI DI DEBOLEZZA" : "WEAKNESSES" }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </p>
            <b-row
              align-v="center"
              v-for="item in dot_plot_skills_data_sorted_desc.slice(0, 6)"
              :key="'for' + item.id"
            >
              <b-col style="font-size: 0.85rem">{{ item.label }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item.id, getValueSkillById(item.id))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item.id) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: red; font-size: 1.1rem"
                ><strong>{{ item.rank }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
        <b-col class="p-0 mb-2" cols="3">
          <b-card
            :class="
              'm-1 h-100 ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <p>
              {{ ita ? "MINACCIA" : "THREATS" }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </p>
            <b-row
              align-v="center"
              v-for="item in skills_rate_sorted_asc.slice(0, 6)"
              :key="'min' + item[0]"
            >
              <b-col style="font-size: 0.85rem">{{
                getSkillById(item[0]).label
              }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item[0], getValueSkillById(item[0]))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item[0]) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: red; font-size: 1.1rem"
                ><strong>{{ Math.round(item[1] * 100) / 100 }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
        <b-col class="p-0 mb-2" cols="3">
          <b-card
            :class="
              'm-1 h-100 ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <p>
              {{ ita ? "OPPORTUNITA'" : "OPPORTUNITIES" }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </p>
            <b-row
              align-v="center"
              v-for="item in skills_rate_sorted_desc.slice(0, 6)"
              :key="'opp' + item[0]"
            >
              <b-col style="font-size: 0.85rem">{{
                getSkillById(item[0]).label
              }}</b-col>
              <b-col cols="3">
                <div
                  :style="
                    'background:' +
                    getBgStat(item[0], getValueSkillById(item[0]))
                  "
                  class="box-small"
                >
                  {{ getValueSkillById(item[0]) }}
                </div>
              </b-col>
              <b-col
                class="text-center"
                cols="3"
                style="color: green; font-size: 1.1rem"
                ><strong>{{ Math.round(item[1] * 100) / 100 }}</strong></b-col
              ></b-row
            >
          </b-card>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            :class="
              'm-1 h-100 ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <!--dot-plot-skills id="coachskills" :data="dot_plot_skills_data" /-->
            <dot-plot-skills
              id="pressing2"
              :width="300"
              :height="150"
              :left="60"
              title="PRESSING"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    56,
                    57,
                    52,
                    51,
                    48,
                    49,
                    50,
                    68,
                    69,
                    70,
                    267,
                    268,
                    269,
                    263,
                    264,
                    262,
                    261,
                    265,
                    266,
                    270,
                  ].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="recovery2"
              :width="300"
              :height="150"
              :left="60"
              title="RECOVERY"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    99,
                    100,
                    96,
                    59,
                    61,
                    60,
                    97,
                    101,
                    77,
                    98,
                    271,
                    272,
                    273,
                    274,
                    275,
                    276,
                    277,
                    278,
                    279,
                  ].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="marking2"
              :width="300"
              :height="150"
              :left="60"
              title="MARKING"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    71,
                    72,
                    73,
                    78,
                    74,
                    76,
                    53,
                    75,
                    225,
                    226,
                    230,
                    58,
                    55,
                    231,
                    233,
                    227,
                    228,
                    229,
                    232,
                    285,
                    286,
                  ].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="buildup2"
              :width="300"
              :height="180"
              :left="60"
              title="BUILD UP"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    23,
                    25,
                    31,
                    19,
                    35,
                    18,
                    32,
                    22,
                    37,
                    33,
                    152,
                    153,
                    154,
                    155,
                    156,
                    157,
                    158,
                    159,
                    160,
                    161,
                    162,
                    163,
                    164,
                    165,
                    166,
                    167,
                  ].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="possession2"
              :width="300"
              :height="180"
              :left="60"
              title="POSSESSION"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    84,
                    80,
                    30,
                    88,
                    34,
                    13,
                    86,
                    87,
                    20,
                    246,
                    247,
                    248,
                    249,
                    250,
                    251,
                    252,
                    253,
                    254,
                    255,
                    256,
                    257,
                    258,
                    259,
                    260,
                  ].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="engagement2"
              :width="300"
              :height="150"
              :left="60"
              title="ENGAGEMENT"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    12,
                    16,
                    17,
                    67,
                    38,
                    39,
                    95,
                    36,
                    21,
                    93,
                    185,
                    186,
                    187,
                    188,
                    189,
                    190,
                    191,
                    192,
                    193,
                    194,
                  ].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="unmarking2"
              :width="300"
              :height="80"
              :left="60"
              title="UNMARKING"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [26, 27, 28, 29, 280, 281, 282, 283, 284].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="dribbling2"
              :width="300"
              :height="130"
              :left="60"
              title="DRIBBLING"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    45,
                    46,
                    47,
                    91,
                    90,
                    174,
                    175,
                    176,
                    177,
                    178,
                    179,
                    180,
                    181,
                    182,
                    183,
                    184,
                  ].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="wp2"
              :width="300"
              :height="180"
              :left="60"
              title="WIDE PLAY"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    62,
                    63,
                    102,
                    103,
                    81,
                    143,
                    144,
                    145,
                    146,
                    147,
                    148,
                    149,
                    150,
                    151,
                    195,
                    196,
                    197,
                    198,
                    199,
                    200,
                    201,
                    202,
                    203,
                    204,
                    205,
                    206,
                  ].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="finishing2"
              :width="300"
              :height="200"
              :left="60"
              title="FINISHING"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    4,
                    5,
                    42,
                    43,
                    44,
                    64,
                    65,
                    92,
                    104,
                    105,
                    106,
                    108,
                    207,
                    208,
                    209,
                    210,
                    211,
                    212,
                    213,
                    214,
                    215,
                    216,
                    217,
                    218,
                    219,
                    220,
                    221,
                    222,
                    223,
                    224,
                  ].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="creativity2"
              :width="300"
              :height="120"
              :left="60"
              title="CREATIVITY"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    6,
                    107,
                    7,
                    8,
                    9,
                    10,
                    40,
                    41,
                    89,
                    168,
                    169,
                    170,
                    171,
                    172,
                    173,
                  ].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              v-if="player && player.ruolo_id == 6"
              id="goalkeeper2"
              :width="300"
              :height="140"
              :left="60"
              title="GOALKEEPER"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    126,
                    127,
                    128,
                    129,
                    130,
                    131,
                    132,
                    133,
                    134,
                    135,
                    136,
                    137,
                    138,
                    139,
                    140,
                    141,
                    142,
                  ].includes(item.id)
                )
              "
            />
            <dot-plot-skills
              id="set_play2"
              :width="300"
              :height="130"
              :left="60"
              title="SET PLAY"
              :data="
                dot_plot_skills_data.filter((item) =>
                  [
                    109,
                    125,
                    124,
                    123,
                    122,
                    234,
                    235,
                    236,
                    237,
                    238,
                    239,
                    240,
                    241,
                    242,
                    243,
                    244,
                    245,
                  ].includes(item.id)
                )
              "
            />
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="p-0 mb-2">
          <div class="card-body pb-1">
            <h4 class="card-title">
              {{ ita ? "Monitoraggio skills" : "Skills monitoring" }}
              <span style="cursor: pointer" @click="ita = !ita">
                <flag
                  v-if="!ita"
                  country="IT"
                  :size="16"
                  class="ml-1"
                  title="IT"
                  style="cursor: pointer"
                ></flag>
                <flag
                  v-if="ita"
                  country="GB"
                  :size="16"
                  class="ml-1"
                  title="EN"
                ></flag>
              </span>
            </h4>
          </div>
        </b-col>
      </b-row>

      <div
        v-for="(array_princ, index_princ) in principles_skills"
        :key="'princ_' + index_princ"
      >
        <div
          v-if="
            (player.ruolo_id == 6 && index_princ != 12) ||
            (player.ruolo_id != 6 && index_princ != 11)
          "
        >
          <b-row align-h="center">
            <b-col class="p-0 mb-2">
              <b-card
                no-body
                :class="
                  'm-1 h-100 text-center ' +
                  (player.giocatore && player.giocatore.sfondo
                    ? 'card-bg-opacity'
                    : '')
                "
              >
                <a
                  style="text-decoration: none"
                  v-b-toggle
                  :href="'#monitoring-' + index_princ"
                  @click.prevent
                  ><h2 class="mb-0 mt-1">
                    {{ principles_title[index_princ] }}
                  </h2></a
                >
              </b-card>
            </b-col>
          </b-row>

          <b-collapse visible :id="'monitoring-' + index_princ">
            <b-row>
              <b-col
                sm="3"
                v-for="key in array_princ"
                :key="'line_' + key"
                class="p-0 mb-2"
              >
                <b-card
                  :class="
                    'm-1 h-100 ' +
                    (player.giocatore && player.giocatore.sfondo
                      ? 'card-bg-opacity'
                      : '')
                  "
                >
                  <b-row>
                    <b-col cols="9" style="font-size: 0.85rem">
                      {{ getSkillById(key).label }}
                    </b-col>
                    <b-col class="text-right">
                      <div
                        :style="
                          'font-weight: bold; font-size: 1rem; color:' +
                          (skills_rate[key] > 0
                            ? 'green'
                            : skills_rate[key] < 0
                            ? 'red'
                            : 'white')
                        "
                      >
                        {{ Math.round(skills_rate[key] * 100) / 100 }}
                      </div>
                    </b-col>
                  </b-row>
                  <!--p style="font-size: 0.85rem;">{{ getSkillById(key).label }}</p-->
                  <line-chart-skill
                    :id="'skill' + key"
                    :data="skills_games[key] ? skills_games[key] : []"
                    :games="moduli_home.concat(moduli_away)"
                  />
                  <b-row>
                    <b-col>
                      <b-row align-v="center">
                        <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                        <b-col class="pl-0">
                          <div
                            title="media"
                            :style="
                              'background:' +
                              getBgStat(key, getMediaSkillById(key))
                            "
                            class="box-small"
                          >
                            {{ getMediaSkillById(key) }}
                          </div>
                        </b-col>
                        <b-col>
                          <div
                            title="player"
                            :style="
                              'background:' +
                              getBgStat(key, getValueSkillById(key))
                            "
                            class="box-large"
                          >
                            {{ getValueSkillById(key) }}
                          </div>
                        </b-col>
                        <b-col class="px-0">
                          <div
                            title="rank"
                            style="font-weight: bold; font-size: 1rem"
                          >
                            {{ getRankSkill(key) }}
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-collapse>
        </div>
      </div>

      <b-row align-h="center">
        <b-col class="p-0 mb-2">
          <b-card
            no-body
            :class="
              'm-1 h-100 text-center ' +
              (player.giocatore && player.giocatore.sfondo
                ? 'card-bg-opacity'
                : '')
            "
          >
            <a
              style="text-decoration: none"
              v-b-toggle
              href="#monitoring-others"
              @click.prevent
              ><h2 class="mb-0 mt-1">OTHERS</h2></a
            >
          </b-card>
        </b-col>
      </b-row>

      <b-collapse visible id="monitoring-others">
        <b-row>
          <b-col
            sm="3"
            v-for="key in other_skill_ids"
            :key="'line_' + key"
            class="p-0 mb-2"
          >
            <b-card
              :class="
                'm-1 h-100 ' +
                (player.giocatore && player.giocatore.sfondo
                  ? 'card-bg-opacity'
                  : '')
              "
            >
              <b-row>
                <b-col cols="9" style="font-size: 0.85rem">
                  {{ getSkillById(key).label }}
                </b-col>
                <b-col class="text-right">
                  <div
                    :style="
                      'font-weight: bold; font-size: 1rem; color:' +
                      (skills_rate[key] > 0
                        ? 'green'
                        : skills_rate[key] < 0
                        ? 'red'
                        : 'white')
                    "
                  >
                    {{ Math.round(skills_rate[key] * 100) / 100 }}
                  </div>
                </b-col>
              </b-row>
              <!--p style="font-size: 0.85rem;">{{ getSkillById(key).label }}</p-->
              <line-chart-skill
                :id="'skill' + key"
                :data="skills_games[key] ? skills_games[key] : []"
                :games="moduli_home.concat(moduli_away)"
              />
              <b-row>
                <b-col>
                  <b-row align-v="center">
                    <b-col cols="2" class="px-0 text-right"> AVG: </b-col>
                    <b-col class="pl-0">
                      <div
                        title="media"
                        :style="
                          'background:' + getBgStat(key, getMediaSkillById(key))
                        "
                        class="box-small"
                      >
                        {{ getMediaSkillById(key) }}
                      </div>
                    </b-col>
                    <b-col>
                      <div
                        title="player"
                        :style="
                          'background:' + getBgStat(key, getValueSkillById(key))
                        "
                        class="box-large"
                      >
                        {{ getValueSkillById(key) }}
                      </div>
                    </b-col>
                    <b-col class="px-0">
                      <div
                        title="rank"
                        style="font-weight: bold; font-size: 1rem"
                      >
                        {{ getRankSkill(key) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-collapse>

      <b-modal
        ref="rankIndexModal"
        :title="'Players index ranking: ' + title_index_rank"
        ok-only
        ok-title="Close"
      >
        <b-row
          align-v="center"
          v-for="(item, index) in players_index_rank"
          :key="index"
          class="p-1"
        >
          <b-col
            cols="1"
            class="text-center"
            :style="player.id == item.id ? 'color:#FF0000' : ''"
          >
            {{ index + 1 }}
          </b-col>
          <b-col cols="2" class="text-center">
            <b-img
              :src="
                item.player && item.player.avatar
                  ? item.player.avatar
                  : '/assets/images/profile.png'
              "
              height="30"
            ></b-img
          ></b-col>
          <b-col :style="player.id == item.id ? 'color:#FF0000' : ''">
            <router-link
              v-if="auth.isAdmin()"
              :to="{
                name: 'ScoutingViewPlayer',
                params: { id: item.player.id },
              }"
              target="_blank"
              :style="
                'cursor: pointer;' +
                (player.id == item.id ? 'color:#FF0000' : '')
              "
              >{{ item.player.name }}
            </router-link>
            <div v-else>{{ item.player.name }}</div>
          </b-col>
          <b-col cols="3">
            <div
              :style="'color:#000000;background:' + item.color"
              class="text-center"
            >
              <strong>{{ item.value }}</strong>
            </div></b-col
          >
        </b-row>
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import moment from "moment";
import videojs from "video.js";
import videojsPlaylist from "videojs-playlist";
videojs.registerPlugin("playlist", videojsPlaylist);
import Progress from "easy-circular-progress";
import Auth from "@/lib/auth.js";
import Multiselect from "vue-multiselect";
import Flag from "@/components/Flag.vue";
import PlayerHeader from "@/components/PlayerHeader.vue";
import PlayerGames from "@/components/PlayerGames.vue";
import HorizontalBarSkill from "@/components/charts/HorizontalBarSkill.vue";
import VerticalBarSkill from "@/components/charts/VerticalBarSkill.vue";
import HorizontalBarRanking from "@/components/charts/HorizontalBarRanking.vue";
//import VerticalBarSkills from "@/components/charts/VerticalBarSkills.vue";
import DotPlotSkills from "@/components/charts/DotPlotSkills.vue";
import LineChartSkill from "@/components/charts/LineChartSkill.vue";
import HalfDonutSkill from "@/components/charts/HalfDonutSkill.vue";
import halfFieldRight from "@/assets/images/half_field_right.png";
import DashedField from "@/assets/images/campo_dashed.svg";
import SimilarPlayers from "@/components/SimilarPlayers.vue";
import TotalSimilarPlayers from "@/components/TotalSimilarPlayers.vue";
import RadarIndex from "@/components/charts/RadarIndex.vue";
import LineChartIndex from "@/components/charts/LineChartIndex.vue";
import loadingImg from "@/assets/images/loading.gif";
//import Imc from "@/components/Imc.vue";
export default {
  data: function () {
    return {
      waiting: false,
      player: null,
      last_team: null,
      player_clusters: [],
      ruoli: [],
      skills: [],
      stats: [],
      stats_range: {},

      stats_range_length: {},
      stats_rank: {},
      stats_rank_value: {},

      skills_rate: {},
      skills_games: {},
      skills_volatility: {},
      players: [],
      moduli_home: [],
      moduli_away: [],
      filters_options: {
        leagues: [],
        player_teams: [],
        teams: [],
        games: [],
        clusters: [
          {
            all: "All clusters",
            clusters: [],
          },
        ],
        roles: [
          {
            macro: "Portieri",
            items: [],
          },
          {
            macro: "Difensori",
            items: [],
          },
          {
            macro: "Centrocampisti",
            items: [],
          },
          {
            macro: "Attaccanti",
            items: [],
          },
        ],
      },
      filters: {
        from: this.getInitialFromDate(),
        to: this.getInitialToDate(),
        //  league: null,
        teams: [],
        games: [],
        //  player_team: null,
        player_teams: [],
        game_from: null,
        game_to: null,
        roles: [],
        leagues: [],
        clusters: [],
        minutes: 600,
        age_from: 0,
        age_to: 0,
      },
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
      ],
      ita: false,
      zone_over: null,
      halfFieldRight,
      swot: "micro",
      swot_micro: [],
      riassunti: [],
      videotags: [],
      playlist: [],
      currentTag: null,
      editorShown: null,
      imageEditor: null,
      width: 700,
      height: 420,
      page: 1,
      gruppo: -1,
      skill_ids: [],
      principles_title: [
        "PRESSING",
        "RECOVERY",
        "MARKING",
        "BUILD UP",
        "POSSESSION",
        "ENGAGEMENT",
        "UNMARKING",
        "DRIBBLING",
        "WIDE PLAY",
        "FINISHING",
        "CREATIVITY",
        "GOALKEEPER",
        "SET PLAY",
      ],
      principles_skills: [[], [], [], [], [], [], [], [], [], [], [], [], []],
      principles_order: [8, 11, 9, 7, 12, 5, 2, 6, 1, 4, 3, 10, 10],
      reload_filters: false,
      tactical_start_seconds: -1,
      tactical_end_seconds: -1,
      fullscreen: false,
      stats_cluster_norm: {},
      skills_weight: {},
      tsp_skill_ids: [],
      other_skill_ids: [],
      players_index_rank: [],
      title_index_rank: null,
      distance_avg_norm: {},
      availability: "N/D",
      player_index: [],
      player_index_predict: [],
      waiting_index: false,
      loadingImg,
    };
  },

  components: {
    Flag,
    PlayerHeader,
    PlayerGames,
    Multiselect,
    HorizontalBarSkill,
    VerticalBarSkill,
    HorizontalBarRanking,
    HalfDonutSkill,
    //  VerticalBarSkills,
    DashedField,
    DotPlotSkills,
    LineChartSkill,
    SimilarPlayers,
    TotalSimilarPlayers,
    Progress,
    RadarIndex,
    LineChartIndex,
    //  Imc,
  },

  created: function () {
    this.getPlayer();
  },

  methods: {
    getPlayer() {
      this.$http
        .get("/scouting/view/player/" + this.$route.params.id + "/0")
        .then((response) => {
          this.player = response.data;
          if (!this.player.id) {
            this.$store.commit("msgPush", {
              msg: "Player not found!",
              status: 0,
            });
            this.$router.push({ name: "ScoutingPlayers" });
          } else {
            if (this.player.giocatore) {
              this.getRiassunti(this.player.giocatore.id);
            }
            this.getRuoli();
            //this.getSkills();
            this.getSkillsPrinciples();
          }
        });
    },

    getSkills() {
      this.waiting = true;
      this.$http.get("/opta/skills/micro").then((response) => {
        this.skills = response.data;

        var ruolo_player = this.player.ruolo;
        if (ruolo_player) {
          this.gruppo = ruolo_player.gruppo;
          for (var i in this.skills) {
            var item = this.skills[i].micro.find(
              (item) => item.gruppo == this.gruppo
            );
            this.skills_weight[this.skills[i].id] = item.peso;
            if (item.micro == 1) {
              this.swot_micro.push(this.skills[i].id);
            }
            this.skill_ids.push(this.skills[i].id);
          }
        }

        for (var i in this.principles_skills) {
          for (var j in this.principles_skills[i]) {
            var skill_id = this.principles_skills[i][j];
            this.tsp_skill_ids.push(skill_id);
          }
        }
        for (var i in this.skill_ids) {
          var skill_id = this.skill_ids[i];
          if (!this.tsp_skill_ids.includes(skill_id)) {
            this.tsp_skill_ids.push(skill_id);
            this.other_skill_ids.push(skill_id);
          }
        }

        let my = this;
        this.swot_micro.sort(function (a, b) {
          return my.tsp_skill_ids.indexOf(a) - my.tsp_skill_ids.indexOf(b);
        });

        this.getFilters();
      });
    },

    getSkillsPrinciples() {
      this.principles_skills = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ];
      this.$http.get("/opta/skills/principles").then((response) => {
        var principles = response.data;
        for (var i in principles) {
          var item = principles[i];
          this.principles_skills[item.principio].push(item.skill_id);
        }
        this.getSkills();
      });
    },

    getRiassunti(id) {
      this.$http.get("/videotag/riassunto/giocatore/" + id).then((response) => {
        this.riassunti = response.data.riassunti;

        this.videotags = [];
        this.playlist = [];
        for (var i in this.riassunti) {
          var videotags = this.riassunti[i].videotags;
          this.videotags = this.videotags.concat(videotags);
          for (var i in videotags) {
            var videotag = videotags[i];
            var source = {
              sources: [
                {
                  src:
                    this.$store.state.apiEndPoint +
                    "/videotag/play/clip/" +
                    videotag.id +
                    "/" +
                    Auth.user.id +
                    "/" +
                    Auth.user.api_token,
                  type: "video/mp4",
                },
              ],
            };
            this.playlist.push(source);
          }
        }

        if (!this.$video) {
          this.$video = videojs("video-wrapper");
          this.$video.on("timeupdate", this.onTimeUpdate);
          this.$video.on("playlistitem", this.autoSelectTag);
          this.$video.playlist(this.playlist);
          this.$video.playlist.autoadvance(0);
        }
      });
    },

    getRuoli() {
      this.waiting = true;
      this.$http.get("/ruoli").then((response) => {
        this.ruoli = response.data;
        for (var i in this.ruoli) {
          var role = this.ruoli[i];
          this.filters_options.roles[role.macro - 1].items.push(role);
        }
        var ruolo_player = this.player.ruolo;
        if (ruolo_player) {
          for (var i in this.ruoli) {
            var ruolo = this.ruoli[i];
            if (
              ruolo.id == ruolo_player.id ||
              (ruolo.id != ruolo_player.id &&
                ruolo.gruppo == ruolo_player.gruppo)
            ) {
              this.filters.roles.push(ruolo);
            }
          }
        }
      });
    },

    getStats() {
      this.stats = [];
      this.waiting = true;
      this.$http
        .get("/scouting/stats/player/" + this.player.id, {
          params: this.filters,
        })
        .then((response) => {
          this.stats_range = response.data.stats_range;
          this.skills_rate = response.data.skills_rate;
          this.skills_games = response.data.skills_games;
          this.moduli_home = response.data.moduli_home;
          this.moduli_away = response.data.moduli_away;
          this.players = response.data.players;
          this.skills_volatility = response.data.skills_volatility;
          this.distance_avg_norm = response.data.distance_avg_norm;
          this.availability = response.data.availability;
          this.stats = response.data.stats;

          this.stats_range_length = {};
          this.stats_rank = {};
          this.stats_rank_value = {};

          this.stats_cluster_norm = {};

          Object.keys(this.stats_range).forEach((key) => {
            const data = this.stats_range[key];
            for (var i in data) {
              if (!this.stats_cluster_norm[data[i].player_id]) {
                var objPlayerStat = {};
                objPlayerStat[data[i].skill_id] = data[i].skill_value_norm;

                this.stats_cluster_norm[data[i].player_id] = objPlayerStat;
              } else {
                var objPlayerStat = this.stats_cluster_norm[data[i].player_id];
                objPlayerStat[data[i].skill_id] = data[i].skill_value_norm;
              }
            }
            this.stats_range_length[key] = data.length;
            const result = [
              ...data
                .reduce((hash, { player_id, skill_value }) => {
                  const current = hash.get(skill_value) || {
                    skill_value,
                    player_ids: [],
                  };
                  current.player_ids.push({ player_id });
                  return hash.set(skill_value, current);
                }, new Map())
                .values(),
            ];
            this.stats_range[key] = result;
          });

          Object.keys(this.stats_range).forEach((key) => {
            const data = this.stats_range[key];

            var skill = this.getSkillById(key);
            var index = -1;
            if (!skill.reverse) {
              index = data.findIndex(
                (item) =>
                  item.player_ids.findIndex(
                    (obj) => obj.player_id === this.player.id
                  ) > -1
              );
            } else {
              index = data
                .slice()
                .reverse()
                .findIndex(
                  (item) =>
                    item.player_ids.findIndex(
                      (obj) => obj.player_id === this.player.id
                    ) > -1
                );
            }
            if (index > -1) {
              var rank_value = index + 1;
              var rank =
                rank_value +
                "/" +
                this.stats_range[key].length +
                " (" +
                this.stats_range_length[key] +
                ")";
              this.stats_rank[key] = rank;
              this.stats_rank_value[key] = rank_value;
            }
          });
          this.waiting = false;
        })
        .catch((error) => {
          console.log(error);
        });

      this.getIndexPlayer();
    },

    getIndexPlayer() {
      this.waiting_index = true;
      this.$http
        .get("/scouting/index/player/" + this.player.id, {
          params: this.filters,
        })
        .then((response) => {
          this.player_index = response.data.playerIndex;
          this.player_index_predict = response.data.playerIndexPredict;
          this.waiting_index = false;
        });
    },

    getIndexSkill(skill_id) {
      var index_obj = {
        value: 0,
        color: "#FFFFFF",
      };
      var index_players = {};
      var index_players_norm = {};
      var array_index_norm = [];
      var max_index = -1;
      var min_index = -1;
      Object.keys(this.stats_cluster_norm).forEach((key) => {
        const obj_stat_player = this.stats_cluster_norm[key];
        index_players[key] =
          obj_stat_player[skill_id] * this.skills_weight[skill_id];
        if (max_index == -1 || max_index < index_players[key]) {
          max_index = index_players[key];
        }
        if (min_index == -1 || min_index > index_players[key]) {
          min_index = index_players[key];
        }
      });

      Object.keys(index_players).forEach((key) => {
        const index_player = index_players[key];
        var index_player_norm = 0;
        if (max_index > min_index) {
          index_player_norm =
            ((index_player - min_index) / (max_index - min_index)) * 100;
          index_player_norm = Math.round(index_player_norm * 100) / 100;
        }
        index_players_norm[key] = index_player_norm;
        array_index_norm.push(index_player_norm);
      });

      if (index_players_norm[this.player.id]) {
        index_obj.value = index_players_norm[this.player.id];

        array_index_norm.sort(function (a, b) {
          return b - a;
        });
        index_obj.color = this.getBgStatIndex(
          index_obj.value,
          array_index_norm
        );
      }

      return index_obj;
    },

    getRankIndexSkill(skill_id) {
      this.players_index_rank = [];
      this.title_index_rank = this.getSkillById(skill_id).label;
      var index_players = {};
      var index_players_norm = {};
      var array_index_norm = [];
      var max_index = -1;
      var min_index = -1;
      Object.keys(this.stats_cluster_norm).forEach((key) => {
        const obj_stat_player = this.stats_cluster_norm[key];
        index_players[key] =
          obj_stat_player[skill_id] * this.skills_weight[skill_id];
        if (max_index == -1 || max_index < index_players[key]) {
          max_index = index_players[key];
        }
        if (min_index == -1 || min_index > index_players[key]) {
          min_index = index_players[key];
        }
      });

      Object.keys(index_players).forEach((key) => {
        const index_player = index_players[key];
        var index_player_norm = 0;
        if (max_index > min_index) {
          index_player_norm =
            ((index_player - min_index) / (max_index - min_index)) * 100;
          index_player_norm = Math.round(index_player_norm * 100) / 100;
        }
        index_players_norm[key] = index_player_norm;
        array_index_norm.push(index_player_norm);
      });

      array_index_norm.sort(function (a, b) {
        return b - a;
      });

      Object.keys(index_players_norm).forEach((key) => {
        var value = index_players_norm[key];

        var index_obj = {
          id: key,
          player: this.getPlayerById(key),
          value: value,
          color: this.getBgStatIndex(value, array_index_norm),
        };

        this.players_index_rank.push(index_obj);
      });

      this.players_index_rank.sort(function (a, b) {
        return b.value - a.value;
      });

      this.$refs.rankIndexModal.show();
    },

    getRankIndexPrinciples(i) {
      this.players_index_rank = [];
      this.title_index_rank = this.principles_title[i];

      var array_index_norm = [];
      var index_players = {};
      var index_players_norm = {};
      var max_index = -1;
      var min_index = -1;

      Object.keys(this.stats_cluster_norm).forEach((key) => {
        const obj_stat_player = this.stats_cluster_norm[key];
        var value = 0;
        for (var j in this.principles_skills[i]) {
          var skill_id = this.principles_skills[i][j];
          if (obj_stat_player[skill_id]) {
            value += obj_stat_player[skill_id] * this.skills_weight[skill_id];
          }
        }
        index_players[key] = value;

        if (max_index == -1 || max_index < index_players[key]) {
          max_index = index_players[key];
        }
        if (min_index == -1 || min_index > index_players[key]) {
          min_index = index_players[key];
        }
      });

      Object.keys(index_players).forEach((key) => {
        const index_player = index_players[key];
        var index_player_norm = 0;
        if (max_index > min_index) {
          index_player_norm =
            ((index_player - min_index) / (max_index - min_index)) * 100;
          index_player_norm = Math.round(index_player_norm * 100) / 100;
        }
        index_players_norm[key] = index_player_norm;
        array_index_norm.push(index_player_norm);
      });

      array_index_norm.sort(function (a, b) {
        return b - a;
      });

      Object.keys(index_players_norm).forEach((key) => {
        var value = index_players_norm[key];

        var index_obj = {
          id: key,
          player: this.getPlayerById(key),
          value: value,
          color: this.getBgStatIndex(value, array_index_norm),
        };

        this.players_index_rank.push(index_obj);
      });

      this.players_index_rank.sort(function (a, b) {
        return b.value - a.value;
      });

      this.$refs.rankIndexModal.show();
    },

    getRankIndexMicro() {
      this.players_index_rank = [];
      this.title_index_rank = "Micro";

      var array_index_norm = [];
      var index_players = {};
      var index_players_norm = {};
      var max_index = -1;
      var min_index = -1;

      Object.keys(this.stats_cluster_norm).forEach((key) => {
        const obj_stat_player = this.stats_cluster_norm[key];
        var value = 0;
        for (var i in this.swot_micro) {
          var skill_id = this.swot_micro[i];
          if (obj_stat_player[skill_id]) {
            value += obj_stat_player[skill_id] * this.skills_weight[skill_id];
          }
        }
        index_players[key] = value;

        if (max_index == -1 || max_index < index_players[key]) {
          max_index = index_players[key];
        }
        if (min_index == -1 || min_index > index_players[key]) {
          min_index = index_players[key];
        }
      });

      Object.keys(index_players).forEach((key) => {
        const index_player = index_players[key];
        var index_player_norm = 0;
        if (max_index > min_index) {
          index_player_norm =
            ((index_player - min_index) / (max_index - min_index)) * 100;
          index_player_norm = Math.round(index_player_norm * 100) / 100;
        }
        index_players_norm[key] = index_player_norm;
        array_index_norm.push(index_player_norm);
      });

      array_index_norm.sort(function (a, b) {
        return b - a;
      });

      Object.keys(index_players_norm).forEach((key) => {
        var value = index_players_norm[key];

        var index_obj = {
          id: key,
          player: this.getPlayerById(key),
          value: value,
          color: this.getBgStatIndex(value, array_index_norm),
        };

        this.players_index_rank.push(index_obj);
      });

      this.players_index_rank.sort(function (a, b) {
        return b.value - a.value;
      });

      this.$refs.rankIndexModal.show();
    },

    getRankIndexMacro() {
      this.players_index_rank = [];
      this.title_index_rank = "Micro";

      var array_index_norm = [];
      var index_players = {};
      var index_players_norm = {};
      var max_index = -1;
      var min_index = -1;

      Object.keys(this.stats_cluster_norm).forEach((key) => {
        const obj_stat_player = this.stats_cluster_norm[key];
        var value = 0;
        for (var i in this.skill_ids) {
          var skill_id = this.skill_ids[i];
          if (obj_stat_player[skill_id]) {
            value += obj_stat_player[skill_id] * this.skills_weight[skill_id];
          }
        }
        index_players[key] = value;

        if (max_index == -1 || max_index < index_players[key]) {
          max_index = index_players[key];
        }
        if (min_index == -1 || min_index > index_players[key]) {
          min_index = index_players[key];
        }
      });

      Object.keys(index_players).forEach((key) => {
        const index_player = index_players[key];
        var index_player_norm = 0;
        if (max_index > min_index) {
          index_player_norm =
            ((index_player - min_index) / (max_index - min_index)) * 100;
          index_player_norm = Math.round(index_player_norm * 100) / 100;
        }
        index_players_norm[key] = index_player_norm;
        array_index_norm.push(index_player_norm);
      });

      array_index_norm.sort(function (a, b) {
        return b - a;
      });

      Object.keys(index_players_norm).forEach((key) => {
        var value = index_players_norm[key];

        var index_obj = {
          id: key,
          player: this.getPlayerById(key),
          value: value,
          color: this.getBgStatIndex(value, array_index_norm),
        };

        this.players_index_rank.push(index_obj);
      });

      this.players_index_rank.sort(function (a, b) {
        return b.value - a.value;
      });

      this.$refs.rankIndexModal.show();
    },

    getBgStatIndex(value, array_range) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var count_array = array_range ? array_range.length : 0;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        for (var i = 0; i <= count_array - 1; i += interval) {
          var to = i + interval;
          if (i + interval > count_array - 1) {
            to = count_array - 1;
          }
          if (index == 0 && value > parseFloat(array_range[i])) {
            return this.bg_opta[index];
          }
          if (index == 9 && value < parseFloat(array_range[to])) {
            return this.bg_opta[index];
          }
          if (
            value <= parseFloat(array_range[i]) &&
            value >= parseFloat(array_range[to])
          ) {
            return this.bg_opta[index > 9 ? 9 : index];
          }
          index++;
        }

        return this.bg_opta[9];
      }
      return "#FFFFFF";
    },

    getFilters() {
      this.waiting = true;
      this.$http
        .get("/scouting/filters/player/" + this.player.id, {
          params: { from: this.filters.from, to: this.filters.to },
        })
        .then((response) => {
          this.filters_options.leagues = response.data.leagues;
          this.filters_options.player_teams = response.data.player_teams;
          this.filters_options.teams = response.data.teams;
          this.filters_options.games = response.data.games;
          this.filters_options.clusters[0].clusters = response.data.clusters;

          this.player_clusters = response.data.player_clusters;

          this.filters.player_teams = [];
          this.filters.player_teams = this.filters_options.player_teams;

          if (this.filters_options.player_teams.length > 0) {
            this.last_team = this.filters_options.player_teams[0];
          }

          this.filters.game_from = null;
          this.filters.game_to = null;
          if (this.filters_options.games.length > 0) {
            var last_game = this.filters_options.games[0];
            this.filters.game_to = last_game.date;

            var first_game = this.filters_options.games[
              this.filters_options.games.length - 1
            ];
            this.filters.game_from = first_game.date;

            this.filters.leagues = [];
            LoopLeague: for (var x in this.filters_options.leagues) {
              var league = this.filters_options.leagues[x];
              for (var i in this.player_clusters) {
                var cluster = this.player_clusters[i];
                for (var y in cluster.cluster_leagues) {
                  if (cluster.cluster_leagues[y].league_id == league.id) {
                    this.filters.leagues.push(league);
                    break LoopLeague;
                  }
                }
              }
            }
            if (
              this.filters.leagues.length == 0 &&
              this.filters_options.leagues.length > 0
            ) {
              this.filters.leagues.push(this.filters_options.leagues[0]);
            }
          }
          this.reload_filters = false;
          this.getStats();
        });
    },
    labelGame({ label, date }) {
      return `${this.formatDate(date)}: ${label}`;
    },
    labelCluster({ name, id }) {
      var index = this.player_clusters.findIndex((item) => item.id == id);
      if (index > -1) {
        return `${name} (player)`;
      }
      return `${name}`;
    },
    formatDate: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
    getInitialFromDate() {
      let month = moment("08-05", "MM-DD");
      let lastMonth = month < moment() ? month : month.subtract(1, "years");
      return lastMonth.format("YYYY-MM-DD");
    },
    getInitialToDate() {
      return moment().format("YYYY-MM-DD");
    },
    getSkillById(id) {
      return this.skills.find((skill) => skill.id == id);
    },
    getDataSkillById(id) {
      var data_player = this.stats.find((stat) => stat.skill_id == id);
      var data = [];
      if (typeof data_player !== "undefined") {
        data.push({
          id: "player",
          order: 1,
          score: parseFloat(data_player.skill_value),
          weight: 1,
          color: this.getBgStat(id, data_player.skill_value),
          label: "PLAYER",
        });
      }
      return data;
    },

    getValueSkillById(id) {
      var data = this.stats.find((stat) => stat.skill_id == id);
      if (data) {
        return Math.round(data.skill_value * 100) / 100;
      }
      return null;
    },

    getBgStat(skill_id, value) {
      if (!isNaN(value)) {
        value = parseFloat(value);
        var skill = this.getSkillById(skill_id);
        var array_range = this.stats_range[skill_id];
        var count_array = array_range ? array_range.length : 0;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        if (skill.reverse) {
          for (var i = count_array - 1; i >= 0; i -= interval) {
            var to = i - interval;
            if (i - interval < 0) {
              to = 0;
            }
            if (index == 0 && value < parseFloat(array_range[i].skill_value)) {
              return this.bg_opta[index];
            }
            if (index == 9 && value > parseFloat(array_range[to].skill_value)) {
              return this.bg_opta[index];
            }
            if (
              value >= parseFloat(array_range[i].skill_value) &&
              value <= parseFloat(array_range[to].skill_value)
            ) {
              return this.bg_opta[index > 9 ? 9 : index];
            }
            index++;
          }
        } else {
          for (var i = 0; i <= count_array - 1; i += interval) {
            var to = i + interval;
            if (i + interval > count_array - 1) {
              to = count_array - 1;
            }
            if (index == 0 && value > parseFloat(array_range[i].skill_value)) {
              return this.bg_opta[index];
            }
            if (index == 9 && value < parseFloat(array_range[to].skill_value)) {
              return this.bg_opta[index];
            }
            if (
              value <= parseFloat(array_range[i].skill_value) &&
              value >= parseFloat(array_range[to].skill_value)
            ) {
              return this.bg_opta[index > 9 ? 9 : index];
            }
            index++;
          }
        }
        return this.bg_opta[9];
      }
      return "#FFFFFF";
    },
    getMaxValueSkillById(id) {
      var array_range = this.stats_range[id];
      if (array_range && array_range.length > 0) {
        return parseFloat(array_range[0].skill_value);
      }
      return 0;
    },
    getMinValueSkillById(id) {
      var array_range = this.stats_range[id];
      if (array_range && array_range.length > 0) {
        return parseFloat(array_range[array_range.length - 1].skill_value);
      }
      return 0;
    },
    getMediaSkillById(id) {
      var array_range = this.stats_range[id];
      if (array_range) {
        var media = 0;
        for (var i in array_range) {
          media = media + parseFloat(array_range[i].skill_value);
        }
        if (array_range) {
          media = media / array_range.length;
        }
        media = Math.round(media * 100) / 100;
        return media;
      }
      return null;
    },
    getPlayerById(id) {
      return this.players.find((player) => player.id == id);
    },

    normalizza(skill_id, value) {
      var min = 30;
      var max = 100;
      var max_skill = this.getMaxValueSkillById(skill_id);
      var min_skill = this.getMinValueSkillById(skill_id);
      if (max_skill > min_skill) {
        return (
          min + ((value - min_skill) / (max_skill - min_skill)) * (max - min)
        );
      }
      return (max - min) / 2;
    },

    onOverZone(zone) {
      this.zone_over = zone;
    },
    getRankSkill(skill_id) {
      if (this.stats_rank[skill_id]) {
        return this.stats_rank[skill_id];
      }
      return "N/D";
    },

    getRankValueSkill(skill_id) {
      if (this.stats_rank_value[skill_id]) {
        return this.stats_rank_value[skill_id];
      }
      return null;
    },

    getDataForHalfDonut(skill_id, opponent = 0) {
      var value = this.getValueSkillById(skill_id, opponent);
      var media = this.getMediaSkillById(skill_id);
      var data = {
        value: value,
        value_media: media,
        color: this.getBgStat(skill_id, value),
        color_media: this.getBgStat(skill_id, media),
        label: value + "%",
        label_media: media + "%",
      };
      return data;
    },

    getDataNormForHalfDonut(skill_id, opponent = 0) {
      var min = 0;
      var max = 100;

      var value = this.getValueSkillById(skill_id, opponent);
      var max_skill = this.getMaxValueSkillById(skill_id);
      var min_skill = this.getMinValueSkillById(skill_id);

      var value_norm = 100;

      if (max_skill > min_skill) {
        value_norm =
          min + ((value - min_skill) / (max_skill - min_skill)) * (max - min);
      }
      var media = this.getMediaSkillById(skill_id);
      var media_norm = 100;
      if (max_skill > min_skill) {
        media_norm =
          min + ((media - min_skill) / (max_skill - min_skill)) * (max - min);
      }

      var data = {
        value: value_norm,
        value_media: media_norm,
        color: this.getBgStat(skill_id, value),
        color_media: this.getBgStat(skill_id, media),
        label: value,
        label_media: media,
      };
      return data;
    },

    normalizzaForDotPlot(value, max_skill, min_skill) {
      var min = 0.1;
      var max = 9.9;
      if (max_skill > min_skill) {
        return (
          min + ((value - min_skill) / (max_skill - min_skill)) * (max - min)
        );
      }
      return (max - min) / 2;
    },

    ranking_data(skill_id) {
      var data = [];
      var array_range = this.stats_range[skill_id];
      var skill = this.getSkillById(skill_id);
      if (skill.reverse) {
        array_range = this.stats_range[skill_id].slice().reverse();
      }
      for (var i in array_range) {
        var item = array_range[i];
        for (var x in item.player_ids) {
          if (item.skill_value != 0) {
            data.push({
              score: item.skill_value,
              color: this.getBgStat(skill_id, item.skill_value),
              player: this.getPlayerById(item.player_ids[x].player_id),
              colorLabel:
                item.player_ids[x].player_id == this.player.id
                  ? "#FF0000"
                  : "#FFFFFF",
              rank: parseInt(i),
            });
            if (data.length == 50) {
              return data;
            }
          }
        }
      }
      return data;
    },
    selectClip(page) {
      this.editorShown = null;
      this.currentTag = this.videotags[page - 1];
      this.$video.playlist.currentItem(page - 1);
      this.tactical_start_seconds = -1;
      this.tactical_end_seconds = -1;
    },
    onTimeUpdate() {
      if (
        this.currentTag &&
        this.currentTag.tactical &&
        this.tactical_start_seconds == -1
      ) {
        this.tactical_start_seconds =
          (moment(this.currentTag.inizio, "HH:mm:ss") -
            moment("00:00:00", "HH:mm:ss")) /
          1000;
        this.tactical_end_seconds =
          (moment(this.currentTag.fine, "HH:mm:ss") -
            moment("00:00:00", "HH:mm:ss")) /
          1000;
        this.$video.currentTime(this.tactical_start_seconds);
      }
      if (this.currentTag && this.currentTag.editors.length > 0) {
        for (var i in this.currentTag.editors) {
          var editor = this.currentTag.editors[i];
          var time =
            (moment(editor.time, "HH:mm:ss") -
              moment(this.currentTag.inizio, "HH:mm:ss")) /
            1000;

          if (this.currentTag.tactical) {
            time = time + this.tactical_start_seconds;
          }

          if (
            editor.download &&
            this.formatDuration(time) ==
              this.formatDuration(this.$video.currentTime()) &&
            editor.id != this.editorShown
          ) {
            if (!this.$video.paused()) {
              this.editorShown = editor.id;
              this.$video.pause();
              this.width = this.$video.currentWidth();
              this.height = this.$video.currentHeight();
              this.imageEditor = editor.foreground_url;
              const seconds = editor.seconds;
              setTimeout(() => {
                this.toggleEditor();
              }, seconds * 1000);
            }
          }
        }
      }
      if (
        this.currentTag &&
        this.currentTag.tactical &&
        this.tactical_end_seconds > -1
      ) {
        if (this.$video.currentTime() >= this.tactical_end_seconds) {
          var nextPage = this.page + 1;
          this.selectClip(nextPage);
        }
      }
    },
    toggleEditor() {
      this.imageEditor = null;
      this.$video.play();
    },
    autoSelectTag() {
      this.currentTag = this.videotags[this.$video.playlist.currentIndex()];
      this.page = this.$video.playlist.currentIndex() + 1;
      this.tactical_start_seconds = -1;
      this.tactical_end_seconds = -1;
    },

    getGiocatoriSimili(skill_ids) {
      var players = [];
      for (var i in skill_ids) {
        var skill = this.getSkillById(skill_ids[i]);
        var peso = 1;
        if (this.gruppo > -1) {
          peso = skill.micro.find((item) => item.gruppo == this.gruppo).peso;
        }
        var data_player = this.stats.find(
          (stat) => stat.skill_id == skill_ids[i]
        );
        if (typeof data_player !== "undefined") {
          var array_data = this.stats_range[skill_ids[i]];
          for (var j in array_data) {
            var item = array_data[j];

            for (var x in item.player_ids) {
              if (item.player_ids[x].player_id != this.player.id) {
                var dist_skill =
                  Math.pow(data_player.skill_value - item.skill_value, 2) *
                  peso;
                var index = players.findIndex(
                  (p) => p.player_id == item.player_ids[x].player_id
                );
                var skill_obj = {
                  value: Math.round(item.skill_value * 100) / 100,
                  color: this.getBgStat(skill_ids[i], item.skill_value),
                };
                if (index == -1) {
                  var player = this.getPlayerById(item.player_ids[x].player_id);
                  var skills = {};
                  skills[skill_ids[i]] = skill_obj;
                  var obj = {
                    player_id: item.player_ids[x].player_id,
                    distance: dist_skill,
                    name: player.name,
                    avatar: player.avatar
                      ? player.avatar
                      : "/assets/images/profile.png",
                    age: player.age ? player.age : "N/D",
                    skills: skills,
                  };
                  players.push(obj);
                } else {
                  players[index].skills[skill_ids[i]] = skill_obj;
                  players[index].distance =
                    players[index].distance + dist_skill;
                }
              }
            }
          }
        }
      }
      var dist_max = 0;
      for (var i in players) {
        var dist = Math.sqrt(players[i].distance);
        if (dist_max < dist) {
          dist_max = dist;
        }
        players[i].distance = dist;
      }
      for (var i in players) {
        players[i].distance =
          Math.round((players[i].distance / dist_max) * 100 * 100) / 100;
        //players[i].distance = Math.round(Math.sqrt(players[i].distance) * 100) / 100;
      }
      players.sort(function (a, b) {
        return a.distance - b.distance;
      });
      if (players.length > 50) {
        players = players.slice(0, 50);
      }

      var skills_player = {};
      for (var i in skill_ids) {
        var skill_value = this.getValueSkillById(skill_ids[i]);
        var skill_obj = {
          value: Math.round(skill_value * 100) / 100,
          color: this.getBgStat(skill_ids[i], skill_value),
        };
        skills_player[skill_ids[i]] = skill_obj;
      }
      var current_player = {
        player_id: this.player.id,
        distance: 0,
        name: this.player.giocatore
          ? this.player.giocatore.cognome
          : this.player.lastName,
        avatar: this.player.giocatore
          ? this.player.giocatore.avatar_url
          : "/assets/images/profile.png",
        age:
          this.getPlayerById(this.player.id) &&
          this.getPlayerById(this.player.id).age
            ? this.getPlayerById(this.player.id).age
            : "N/D",
        skills: skills_player,
      };
      players.unshift(current_player);
      return players;
    },

    getMediaVolatility(skill_ids) {
      var media = 0;
      if (skill_ids.length > 0) {
        for (var i in skill_ids) {
          media = media + this.skills_volatility[skill_ids[i]];
        }
        media = media / skill_ids.length;
      } else {
        var count = 0;
        Object.keys(this.skills_volatility).forEach((key) => {
          media = media + this.skills_volatility[key];
          count++;
        });
        media = media / count;
      }
      media = Math.round(media * 100) / 100;
      return media;
    },

    age: function (date) {
      var birthday = moment(date);
      if (birthday.isValid()) {
        return moment().diff(birthday, "years");
      }
      return 0;
    },

    player_index_principle: function (x) {
      var array_index = [];
      for (var i in this.player_index) {
        var obj = {
          date: this.player_index[i].to,
          value: this.player_index[i].principles_index
            ? this.player_index[i].principles_index[x]
            : null,
        };
        array_index.push(obj);
      }
      return array_index;
    },

    player_index_principle_predict: function (x) {
      var array_index = [];
      for (var i in this.player_index_predict) {
        var obj = {
          date: this.player_index_predict[i].to,
          value: this.player_index_predict[i].principles_index
            ? this.player_index_predict[i].principles_index[x]
            : null,
        };
        array_index.push(obj);
      }
      return array_index;
    },
  },
  watch: {
    "filters.from": function () {
      this.reload_filters = true;
      //  this.getFilters();
    },

    "filters.to": function () {
      this.reload_filters = true;
      //  this.getFilters();
    },
  },

  computed: {
    auth: function () {
      return Auth;
    },
    dot_plot_skills: function () {
      var excludes = [];
      return this.skills.filter((item) => !excludes.includes(item.id));
    },

    goals_min_player_data: function () {
      var skill_ids = [110, 111, 112, 113, 114, 115];
      var skill_labels = [
        "1-15",
        "16-30",
        "31-45+",
        "46-60",
        "61-75",
        "75-90+",
      ];
      var data = [];
      for (var i in skill_ids) {
        var item = this.stats.find((stat) => stat.skill_id == skill_ids[i]);
        if (typeof item !== "undefined") {
          data.push({
            score: item.skill_value,
            color: this.getBgStat(skill_ids[i], item.skill_value),
            label: skill_labels[i],
            media: this.getMediaSkillById(skill_ids[i]),
            rank: this.getRankSkill(skill_ids[i]),
          });
        }
      }
      return data;
    },

    dot_plot_skills_data: function () {
      var data = [];
      for (var i in this.dot_plot_skills) {
        var skill = this.dot_plot_skills[i];
        var max_skill = this.getMaxValueSkillById(skill.id);
        var min_skill = this.getMinValueSkillById(skill.id);
        var value = this.getValueSkillById(skill.id);
        var media = this.getMediaSkillById(skill.id);
        var obj_data = {
          id: skill.id,
          label: skill.label,
          value: value,
          rank: this.getRankSkill(skill.id),
          rank_value: this.getRankValueSkill(skill.id),
          score: !skill.reverse
            ? this.normalizzaForDotPlot(value, max_skill, min_skill)
            : 9.9 - this.normalizzaForDotPlot(value, max_skill, min_skill),
          color: this.getBgStat(skill.id, value),
          media_score: !skill.reverse
            ? this.normalizzaForDotPlot(media, max_skill, min_skill)
            : 9.9 - this.normalizzaForDotPlot(media, max_skill, min_skill),
          rate: Math.round(this.skills_rate[skill.id] * 100) / 100,
          volatility: this.skills_volatility[skill.id],
          scores: [],
        };
        var array_range = this.stats_range[skill.id];
        for (var j in array_range) {
          var item = array_range[j];

          for (var x in item.player_ids) {
            var player = this.getPlayerById(item.player_ids[x].player_id);
            obj_data.scores.push([
              !skill.reverse
                ? this.normalizzaForDotPlot(
                    item.skill_value,
                    max_skill,
                    min_skill
                  )
                : 9.9 -
                  this.normalizzaForDotPlot(
                    item.skill_value,
                    max_skill,
                    min_skill
                  ),
              player.name,
              player.avatar ? player.avatar : "/assets/images/profile.png",
              "/scouting/view/player/" + player.id,
            ]);
          }
        }
        data.push(obj_data);
      }

      return data;
    },

    skills_rate_sorted_asc: function () {
      var sortable = [];
      for (var skill_id in this.skills_rate) {
        if (
          this.swot == "macro" ||
          (this.swot == "micro" && this.swot_micro.includes(parseInt(skill_id)))
        ) {
          sortable.push([skill_id, this.skills_rate[skill_id]]);
        }
      }
      sortable.sort(function (a, b) {
        return a[1] - b[1];
      });
      return sortable;
    },

    skills_rate_sorted_desc: function () {
      var sortable = [];
      for (var skill_id in this.skills_rate) {
        if (
          this.swot == "macro" ||
          (this.swot == "micro" && this.swot_micro.includes(parseInt(skill_id)))
        ) {
          sortable.push([skill_id, this.skills_rate[skill_id]]);
        }
      }
      sortable.sort(function (a, b) {
        return b[1] - a[1];
      });
      return sortable;
    },

    dot_plot_skills_data_sorted_asc: function () {
      var sorted = this.dot_plot_skills_data.slice();
      if (this.swot == "micro") {
        sorted = sorted.filter((item) => this.swot_micro.includes(item.id));
      }
      sorted.sort(function (a, b) {
        if (a.rank_value === b.rank_value) {
          return b.score - b.media_score - (a.score - a.media_score);
        }
        return a.rank_value - b.rank_value;
      });
      return sorted;
    },

    dot_plot_skills_data_sorted_desc: function () {
      var sorted = this.dot_plot_skills_data.slice();
      if (this.swot == "micro") {
        sorted = sorted.filter((item) => this.swot_micro.includes(item.id));
      }
      sorted.sort(function (a, b) {
        if (a.rank_value === b.rank_value) {
          return a.score - a.media_score - (b.score - b.media_score);
        }
        return b.rank_value - a.rank_value;
      });
      return sorted;
    },
    showImageEditor() {
      if (this.imageEditor) {
        return true;
      }
      return false;
    },
    widthEditor() {
      return this.width;
    },

    heightEditor() {
      return this.height;
    },
    player_meter: function () {
      var meter = [];
      var skill_ids = this.swot == "micro" ? this.swot_micro : this.skill_ids;
      for (var i in skill_ids) {
        var skill = this.getSkillById(skill_ids[i]);
        var value = this.getValueSkillById(skill.id);
        if (value !== "N/D") {
          var obj = {
            id: skill.id,
            name: skill.label,
            value: value,
            color: this.getBgStat(skill.id, value),
            rank: this.getRankValueSkill(skill.id),
          };
          meter.push(obj);
        }
      }
      meter.sort(
        (a, b) =>
          this.bg_opta.indexOf(a.color) - this.bg_opta.indexOf(b.color) ||
          (isNaN(a.rank) ? 1000 : a.rank) - (isNaN(b.rank) ? 1000 : b.rank)
      );
      return meter;
    },

    principles_index: function () {
      var principles_index = [];
      for (var i in this.principles_title) {
        var index_player = 0;
        var array_index_norm = [];
        var index_players = {};
        var index_players_norm = {};
        var max_index = -1;
        var min_index = -1;

        Object.keys(this.stats_cluster_norm).forEach((key) => {
          const obj_stat_player = this.stats_cluster_norm[key];
          var value = 0;
          for (var j in this.principles_skills[i]) {
            var skill_id = this.principles_skills[i][j];
            if (obj_stat_player[skill_id]) {
              value += obj_stat_player[skill_id] * this.skills_weight[skill_id];
            }
          }
          index_players[key] = value;

          if (max_index == -1 || max_index < index_players[key]) {
            max_index = index_players[key];
          }
          if (min_index == -1 || min_index > index_players[key]) {
            min_index = index_players[key];
          }
        });

        Object.keys(index_players).forEach((key) => {
          const index_player = index_players[key];
          var index_player_norm = 0;
          if (max_index > min_index) {
            index_player_norm =
              ((index_player - min_index) / (max_index - min_index)) * 100;
            index_player_norm = Math.round(index_player_norm * 100) / 100;
          }
          index_players_norm[key] = index_player_norm;
          array_index_norm.push(index_player_norm);
        });

        var color = "#FFFFFF";
        if (index_players_norm[this.player.id]) {
          index_player = index_players_norm[this.player.id];

          array_index_norm.sort(function (a, b) {
            return b - a;
          });

          color = this.getBgStatIndex(index_player, array_index_norm);
        }

        var obj = {
          axis: this.principles_title[i].substr(0, 3),
          value: index_player,
          color: color,
          order: this.principles_order[i],
        };
        principles_index.push(obj);
      }

      return principles_index;
    },

    principles_index_by_role: function () {
      var array = [];
      if (this.player && this.player.ruolo_id == 6) {
        array = this.principles_index.slice(0, 12);
      } else {
        array = this.principles_index.slice(0, 11);
        array.push(this.principles_index[12]);
      }
      return array;
    },

    principles_index_media: function () {
      var principles_index = [];
      for (var i in this.principles_title) {
        var index_player = 0;
        var array_index_norm = [];
        var index_players = {};
        var index_players_norm = {};
        var max_index = -1;
        var min_index = -1;

        Object.keys(this.stats_cluster_norm).forEach((key) => {
          const obj_stat_player = this.stats_cluster_norm[key];
          var value = 0;
          for (var j in this.principles_skills[i]) {
            var skill_id = this.principles_skills[i][j];
            if (obj_stat_player[skill_id]) {
              value += obj_stat_player[skill_id] * this.skills_weight[skill_id];
            }
          }
          index_players[key] = value;

          if (max_index == -1 || max_index < index_players[key]) {
            max_index = index_players[key];
          }
          if (min_index == -1 || min_index > index_players[key]) {
            min_index = index_players[key];
          }
        });

        Object.keys(index_players).forEach((key) => {
          const index_player = index_players[key];
          var index_player_norm = 0;
          if (max_index > min_index) {
            index_player_norm =
              ((index_player - min_index) / (max_index - min_index)) * 100;
            index_player_norm = Math.round(index_player_norm * 100) / 100;
          }
          index_players_norm[key] = index_player_norm;
          array_index_norm.push(index_player_norm);
        });

        var media = 0;
        Object.keys(index_players_norm).forEach((key) => {
          const index_player = index_players_norm[key];
          media += index_player;
        });

        media = media / array_index_norm.length;

        media = Math.round(media * 100) / 100;

        var obj = {
          axis: this.principles_title[i].substr(0, 3),
          value: media,
          order: this.principles_order[i],
        };
        principles_index.push(obj);
      }

      return principles_index;
    },

    principles_index_media_by_role: function () {
      var array = [];
      if (this.player && this.player.ruolo_id == 6) {
        array = this.principles_index_media.slice(0, 12);
      } else {
        array = this.principles_index_media.slice(0, 11);
        array.push(this.principles_index_media[12]);
      }
      return array;
    },

    micro_index: function () {
      var index_player = 0;
      var array_index_norm = [];
      var index_players = {};
      var index_players_norm = {};
      var max_index = -1;
      var min_index = -1;

      Object.keys(this.stats_cluster_norm).forEach((key) => {
        const obj_stat_player = this.stats_cluster_norm[key];
        var value = 0;
        for (var i in this.swot_micro) {
          var skill_id = this.swot_micro[i];
          if (obj_stat_player[skill_id]) {
            value += obj_stat_player[skill_id] * this.skills_weight[skill_id];
          }
        }
        index_players[key] = value;

        if (max_index == -1 || max_index < index_players[key]) {
          max_index = index_players[key];
        }
        if (min_index == -1 || min_index > index_players[key]) {
          min_index = index_players[key];
        }
      });

      Object.keys(index_players).forEach((key) => {
        const index_player = index_players[key];
        var index_player_norm = 0;
        if (max_index > min_index) {
          index_player_norm =
            ((index_player - min_index) / (max_index - min_index)) * 100;
          index_player_norm = Math.round(index_player_norm * 100) / 100;
        }
        index_players_norm[key] = index_player_norm;
        array_index_norm.push(index_player_norm);
      });

      var color = "#FFFFFF";
      if (index_players_norm[this.player.id]) {
        index_player = index_players_norm[this.player.id];

        array_index_norm.sort(function (a, b) {
          return b - a;
        });

        color = this.getBgStatIndex(index_player, array_index_norm);
      }

      var obj = {
        value: index_player,
        color: color,
      };

      return obj;
    },

    total_index: function () {
      var index_player = 0;
      var array_index_norm = [];
      var index_players = {};
      var index_players_norm = {};
      var max_index = -1;
      var min_index = -1;

      Object.keys(this.stats_cluster_norm).forEach((key) => {
        const obj_stat_player = this.stats_cluster_norm[key];
        var value = 0;
        for (var i in this.skill_ids) {
          var skill_id = this.skill_ids[i];
          if (obj_stat_player[skill_id]) {
            value += obj_stat_player[skill_id] * this.skills_weight[skill_id];
          }
        }
        index_players[key] = value;

        if (max_index == -1 || max_index < index_players[key]) {
          max_index = index_players[key];
        }
        if (min_index == -1 || min_index > index_players[key]) {
          min_index = index_players[key];
        }
      });

      Object.keys(index_players).forEach((key) => {
        const index_player = index_players[key];
        var index_player_norm = 0;
        if (max_index > min_index) {
          index_player_norm =
            ((index_player - min_index) / (max_index - min_index)) * 100;
          index_player_norm = Math.round(index_player_norm * 100) / 100;
        }
        index_players_norm[key] = index_player_norm;
        array_index_norm.push(index_player_norm);
      });

      var color = "#FFFFFF";
      if (index_players_norm[this.player.id]) {
        index_player = index_players_norm[this.player.id];

        array_index_norm.sort(function (a, b) {
          return b - a;
        });

        color = this.getBgStatIndex(index_player, array_index_norm);
      }

      var obj = {
        value: index_player,
        color: color,
      };

      return obj;
    },

    principles_title_index: function () {
      if (this.player && this.player.ruolo_id == 6) {
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      } else {
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12];
      }
    },

    player_index_micro: function () {
      var array_index = [];
      for (var i in this.player_index) {
        var obj = {
          date: this.player_index[i].to,
          value: this.player_index[i].micro_index,
        };
        array_index.push(obj);
      }
      return array_index;
    },

    player_index_micro_predict: function () {
      var array_index = [];
      for (var i in this.player_index_predict) {
        var obj = {
          date: this.player_index_predict[i].to,
          value: this.player_index_predict[i].micro_index,
        };
        array_index.push(obj);
      }
      return array_index;
    },

    player_index_macro: function () {
      var array_index = [];
      for (var i in this.player_index) {
        var obj = {
          date: this.player_index[i].to,
          value: this.player_index[i].macro_index,
        };
        array_index.push(obj);
      }
      return array_index;
    },

    player_index_macro_predict: function () {
      var array_index = [];
      for (var i in this.player_index_predict) {
        var obj = {
          date: this.player_index_predict[i].to,
          value: this.player_index_predict[i].macro_index,
        };
        array_index.push(obj);
      }
      return array_index;
    },
  },
  beforeDestroy() {
    if (this.$video) {
      this.$video.off("timeupdate", this.onTimeUpdate);
      this.$video.dispose();
      this.$video = null;
    }
  },
  filters: {},
};
</script>
<style lang="scss">
.video-js {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.vjs-default-skin .vjs-big-play-button {
  $big-play-width: 3em;
  $big-play-height: 1.5em;
  left: 50%;
  top: 50%;
  margin-left: -($big-play-width / 2);
  margin-top: -($big-play-height / 2);
}
</style>

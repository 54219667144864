<template>
  <div id="lineindex_container">
    <svg :id="'lineindex' + id"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";
import moment from "moment";

export default {
  props: {
    id: {
      type: String,
      required: true,
      default: null,
    },
    data: {
      type: Array,
      required: true,
    },
    data_predict: {
      type: Array,
      default: () => [],
    },
    width: {
      type: Number,
      default: 800,
    },
    height: {
      type: Number,
      default: 200,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {},

  mounted() {
    this.plotChart();
  },

  methods: {
    plotChart() {
      const margin = { top: 20, right: 30, bottom: 50, left: 40 };
      const width = this.width - margin.left - margin.right;
      const height = this.height - margin.top - margin.bottom;

      var parseTime = d3.timeParse("%Y-%m-%d");

      const comp = this;

      this.data.forEach(function (d) {
        d.date = comp.formatDate(d.date);
      });

      this.data_predict.forEach(function (d) {
        d.date = comp.formatDate(d.date);
      });

      const x = d3
        .scalePoint()
        .domain(
          this.data_predict.map(function (d) {
            return d.date;
          })
        )
        .range([0, width]);

      const y = d3.scaleLinear().domain([0, 100]).range([height, 0]);

      const line2 = d3
        .line()
        .x(function (d) {
          return x(d.date);
        })
        .y(function (d) {
          return y(d.value);
        });

      const line = d3
        .line()
        .x(function (d) {
          return x(d.date);
        })
        .y(function (d) {
          return y(d.value);
        })
        .curve(d3.curveMonotoneX);

      const svg = d3
        .select("#lineindex" + this.id)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr(
          "viewBox",
          `0 0 ${width + margin.left + margin.right} ${
            height + margin.top + margin.bottom
          }`
        );

      this.chart = svg
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // add the x Axis
      this.chart
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x).tickSize(0))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("font-size", "0.5rem")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)");

      // add the y Axis
      this.chart
        .append("g")
        .attr("class", "grid")
        .call(d3.axisLeft(y).tickSize(-width).ticks(5));
      //.tickFormat(""));

      this.chart
        .append("path")
        .datum(this.data_predict)
        .attr("class", "lineindex2")
        .attr("d", line2);

      this.chart
        .selectAll(".dot2")
        .data(this.data_predict.slice(10))
        .enter()
        .append("circle")
        .attr("class", "dot2")
        .attr("cx", function (d) {
          return x(d.date);
        })
        .attr("cy", function (d) {
          return y(d.value);
        })
        .attr("r", 5);

      this.chart
        .selectAll(".dotlabel2")
        .data(this.data_predict.slice(10))
        .enter()
        .append("text")
        .attr("class", "dotlabel2")
        .attr("x", function (d) {
          return x(d.date);
        })
        .attr("y", function (d) {
          return y(d.value);
        })
        .attr("dy", "-0.8em")
        .style("font-size", "10px")
        .attr("fill", "#FFFFFF")
        .text(function (d) {
          return d.value ? d.value : "N/D";
        });

      this.chart
        .append("path")
        .datum(this.data)
        .attr("class", "lineindex")
        .attr("d", line);

      this.chart
        .selectAll(".dot")
        .data(this.data)
        .enter()
        .append("circle")
        .attr("class", "dot")
        .attr("cx", function (d) {
          return x(d.date);
        })
        .attr("cy", function (d) {
          return y(d.value);
        })
        .attr("r", 5);

      this.chart
        .selectAll(".dotlabel")
        .data(this.data)
        .enter()
        .append("text")
        .attr("class", "dotlabel")
        .attr("x", function (d) {
          return x(d.date);
        })
        .attr("y", function (d) {
          return y(d.value);
        })
        .attr("dy", "-0.8em")
        .style("font-size", "10px")
        .attr("fill", "#FFFFFF")
        .text(function (d) {
          return d.value ? d.value : "N/D";
        });
    },

    formatDate: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YY");
      }
      return "";
    },
  },
};
</script>
<style>
.tooltip {
  position: absolute;
  z-index: 999;
  width: 150px;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -mox-box-shadow: 4px 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rbga(0, 0, 0, 0.4);
  pointer-events: none;
}
.tooltip.hidden {
  opacity: 0;
}
.tooltip p {
  margin: 0;
  font-size: 10px;
  line-height: 20px;
}

.grid {
  font: 12px sans-serif;
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.grid path {
  stroke-width: 0;
}

/* 13. Basic Styling with CSS */

/* Style the lines by removing the fill and applying a stroke */
.lineindex {
  fill: none;
  stroke: #28a745;
  stroke-width: 3;
}

.lineindex2 {
  fill: none;
  stroke: #ffa500;
  stroke-width: 1;
}

/* Style the dots by assigning a fill and stroke */
.dot {
  fill: #28a745;
  stroke: #fff;
}

.dot2 {
  fill: #ffa500;
  stroke: #fff;
}

.dotlabel {
  font-weight: bold;
}

.dotlabel2 {
  font-weight: bold;
}
</style>
